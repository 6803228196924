import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { logDefault } from "../Utils/logger";
import { deleteData, getData, patchData } from "../feathers";

const CLASSNAME = 'CompanyManager';
const servicename = ENUM_SERVICES.COMPANIES;

export const openCompany = async (id: number, addBreadcrumb: Function) => {
	logDefault(CLASSNAME, 'info', 'openCompany id:' + id, null)
	await getData(ENUM_SERVICES.COMPANIES, id).then((company) => {
		addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + id);
	})
}

export const deleteCompany = async (company: any, minusBreadcrumbs: Function, setToastParams: Function) => {
	const { addresses, contactPersons } = company;
	await deleteData(servicename, company.id).then(async () => {
		if (addresses) {
			for (let address of addresses) {
				await deleteData(ENUM_SERVICES.ADDRESSES, address.id)
			}
		}
		if (contactPersons) {
			for (let contactPerson of contactPersons) {
				await deleteData(ENUM_SERVICES.COMPANYCONTACTPERSONS, contactPerson.id)
			}
		}
		minusBreadcrumbs();
	}).catch((error) => {
		setToastParams({ title: 'Unternehmen konnte nicht gelöscht werden', message: error.message })
	})
}

export const toggleCompanyStatus = async (company: any, currentUser: any, isReader: any, setCompany: Function, setReadonly: Function, setToastParams: Function) => {
	company.active = company.active ? 0 : 1;
	return await patchCompany(company, currentUser, isReader, setCompany, setReadonly, setToastParams)
}
export const patchCompany = async (company: any, currentUser: any, isReader: any, setCompany: Function, setReadonly: Function, setToastParams: Function) => {

	logDefault(CLASSNAME, 'info', 'company:', company)
	return await patchData(servicename, company, currentUser).then((company) => {
		if (setCompany) {
			setCompany(company);
		}
		if (setReadonly) {
			setReadonly(!company.active || isReader)
		}
		return company;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Unternehmen mit der Nummer "' + company.id + '" konnte nicht aktualisiert werden', message: error.message })
		} else {
			throw error
		}
	});
}

export const getCompany = async (id: any, isReader: any, setCompany: Function, setReadonly: Function, setToastParams: Function) => {
	return await getData(servicename, id).then((company) => {
		if (setCompany) {
			setCompany(company);
		}
		if (setReadonly) {
			setReadonly(!company.active || isReader)
		}
		return company;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Unternehmen mit der Nummer "' + id + '" konnte nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}
