import React, { useContext, useState, useEffect } from "react";
import { updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, logDefault, LoggerContext } from "../Utils/logger";
import { useCurrentUser } from "./CurrentUserContext";
import { fetchDataWithTimeout } from "../snippets";
import { readOverviewData } from "../Overviews/OverviewManager";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";

const CLASSNAME = 'EAACasesProvider';
const listenernames = ['created', 'patched', 'removed'];
const providername = 'EAACasesProvider';
const servicename = 'eaa-cases';
const EAACasesContext = React.createContext();
const EAACasesInitContext = React.createContext();

export function useEAACasesList() {
	return useContext(EAACasesContext);
}

export function useEAACasesInit() {
	return useContext(EAACasesInitContext);
}

export function EAACasesProvider({ children }) {
	const currentUser = useCurrentUser();
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	const initProvider = () => {
		if (!mounted) {
			setMounted(true)
		}
	}

	useEffect(() => {
		if (listItemChanged) {
			logContext(CLASSNAME, 'info', 'ListItemChanged', listItemChanged)
			if (typeof listItemChanged.status === 'object') {
				listItemChanged.status = listItemChanged.status.alias
			}
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(CLASSNAME, 'info', '########### ' + providername + ' useEffect update list entries: ' + list.length);
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])


	useEffect(() => {
		if (mounted) {
			logContext(CLASSNAME, 'info', '########### ' + providername + ' getData');
			setTimeout(async () => await readOverviewData(ENUM_DATAVIEWS_CONFIG.EAACASES, 250, setList, null), 1)
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted, currentUser])

	return (
		<EAACasesInitContext.Provider value={initProvider}>
			<EAACasesContext.Provider value={list}>
				{children}
			</EAACasesContext.Provider>
		</EAACasesInitContext.Provider>
	)
}