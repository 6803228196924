import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils"
import { useEffect } from "react";

export const DropdownChangesChecked = ({ id, label, disabled, formik, className, optionValue, itemLabel, itemTemplate, valueTemplate, options, placeholder, filter, filterBy, onChange, handleChange, showClear, tabIndex, idError, emptyFilterMessage, filterTemplate, classNameDropdown }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};

	useEffect(() => {

	}, [])

	const handleOnChange = (e) => {
		if (onChange) {
			onChange(e.value)
		}
		if (handleChange) {
			handleChange(e.value)
		}
		formik.handleBlur(e)
		formik.handleChange(e);
	}

	return (
		<div className={className + ' field col mr-3'}>
			<label htmlFor={id} className='block'>{label}</label>
			<Dropdown key={'dropdown-' + id}

				tabIndex={tabIndex}
				disabled={disabled}
				type='text'
				filter={filter}
				filterBy={filterBy}
				emptyFilterMessage={emptyFilterMessage || 'Kein Eintrag gefunden'}
				filterTemplate={filterTemplate}
				itemTemplate={itemTemplate}
				valueTemplate={valueTemplate}
				optionLabel={itemLabel}
				optionValue={optionValue}
				id={id}
				value={formik.values[id]}
				options={options}
				onChange={handleOnChange}
				placeholder={placeholder}
				autoComplete="off"
				onFocus={(event) => { event.target.setAttribute('autocomplete', 'new-password') }}
				className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + classNameDropdown}
				showClear={showClear}
				emptyMessage='Keine Auswahl vorhanden' />
			{getFormErrorMessage(idError ? idError : id)}
		</div>
	)

}

