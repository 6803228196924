import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ENUM_AWESOMEICON_SIZE, Icon } from "../Components/Icons";
import { findData } from "../feathers";
import { logDefault } from "../Utils/logger";
import { ENUM_ROUTES, ROUTES } from "./Routes";
import { useTabViewsIndex } from "../Persistence/CurrentUserContext";
import { ENUM_TABVIEWS } from "../Enums/ENUM_TABVIEWS";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { useDataviewsConfig } from "../Persistence/ConfigurationsContext";

const CLASSNAME = 'Breadcrumbs';
export const Breadcrumbs = () => {
	const getDataviewConfig = useDataviewsConfig();
	const navigate = useNavigate();
	const location = useLocation();
	const menubarItemSelected = useTabViewsIndex();
	const [items, setItems] = useState();
	const [home, setHome] = useState(ROUTES['DASHBOARD']);

	const onClick = (route) => {
		if (route) {
			navigate('/' + route)
		}
	}

	useEffect(() => {

		const parts = location.pathname.replace('Fallverwaltung', 'Faelle').split('/')
		parts.shift();
		const routeName = parts.shift()

		const enumKey = Object.keys(ENUM_ROUTES).filter(x => ENUM_ROUTES[x] === (routeName || "Dashboard"));

		const route = ROUTES[enumKey];
		const id = parts.shift()
		const items = [];
		logDefault(CLASSNAME, 'info', 'routename: ' + (routeName || "Dashboard") + ' id:' + id, ROUTES[enumKey]);
		setHome(route)

		if (route.service && id && /^\d+$/.test(id)) {
			findData(route.service, { id }).then((parent) => {
				if (parent && parent.length > 0) {
					const result = parent[0]
					let label = result.name + ''
					if (route.route === ENUM_ROUTES.JOBPROFILES) {
						label = result.company.name + ' | ' + result.description;
					} else if (route.route === ENUM_ROUTES.EAACASES) {
						label = result.company.name + ' | ' + result.name;
					} else if (route.route === ENUM_ROUTES.PROVIDERACTIVITIES) {
						label = result.name;
					} else if (route.route === ENUM_ROUTES.REQUESTDISTRIBUTION) {
						label = result.subject;
					}
					label = label.length > 50 ? label.substring(0, 49) + '...' : label;
					items.push(<li key={'breadcrumb_angle_1'} className="px-2"><i className={`fa fa-angle-right text-500'}`}></i></li>)
					items.push(<li key={'breadcrumb_label_1'} className="px-2"><a onClick={() => { onClick(route.route) }} className={`cursor-pointer text-500 white-space-nowrap`}>{route.label}</a></li>)
					items.push(<li key={'breadcrumb_angle_2'} className="px-2"><i className='fa fa-angle-right color-text-blue'></i></li>)
					items.push(<li key={'breadcrumb_label_2'} className="px-2"><a className='cursor-pointer color-text-blue white-space-nowrap'>{label}</a></li>)
					setItems(items);
				}
			})
		} else if (id === 'new') {
			items.push(<li key={'breadcrumb_angle_1'} className="px-2"><i className={`fa fa-angle-right text-500'}`}></i></li>)
			items.push(<li key={'breadcrumb_label_1'} className="px-2"><a onClick={() => { onClick(route.route) }} className={`cursor-pointer text-500 white-space-nowrap`}>{route.label}</a></li>)
			items.push(<li key={'breadcrumb_angle_2'} className="px-2"><i className='fa fa-angle-right color-text-blue'></i></li>)
			items.push(<li key={'breadcrumb_label_2'} className="px-2"><a className='cursor-pointer color-text-blue white-space-nowrap'>{route.labelNew}</a></li>)
			setItems(items);

		} else {
			items.push(<li key={'breadcrumb_angle_1'} className="px-2"><i className={`fa fa-angle-right text-500'}`}></i></li>)
			items.push(<li key={'breadcrumb_label_1'} className="px-2"><a className={`cursor-pointer text-500 white-space-nowrap`}>{route.label}</a></li>)
			if (route.route === ENUM_ROUTES.OVERVIEWS) {
				const enum_dataview_config = getDataviewConfig(menubarItemSelected ? menubarItemSelected[ENUM_TABVIEWS.OVERVIEWS] : ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES)
				items.push(<li key={'breadcrumb_angle_2'} className="px-2"><i className='fa fa-angle-right color-text-blue'></i></li>)
				items.push(<li key={'breadcrumb_label_2'} className="px-2"><a className='cursor-pointer color-text-blue white-space-nowrap'>{enum_dataview_config.headline}</a></li>)
			}
			setItems(items);
		}

	}, [location, menubarItemSelected])

	return (
		<ul className="list-none p-0 m-0 flex align-items-center font-medium overflow-x-auto">
			<li key='breadcrumb_home' className="pr-3">
				<a className="cursor-pointer">
					{home.awesomeIcon ? <Icon ENUM_AWESOMEICON={home.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className='text-500' /> : <i className={`${home.icon} text-500`}></i>}
				</a>
			</li>
			{items}
		</ul>
	)
}