import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useState, useEffect } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { ENUM_ICONS } from "../Components/Icons";
import { TemplateDataView } from "../Components/TemplateDataView";
import { useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { logDefault } from "../Utils/logger";
import { getData, patchData } from "../feathers";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { AssociatedContactForm, getEmptyAssociatedContact, validateAssociatedContact } from "./AssociatedContactForm";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useAssociatedContactsList } from "../Persistence/AssociatedContactsContext";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useNavigate } from "react-router-dom";
import { useConfigurationsList, useDataviewsConfig } from "../Persistence/ConfigurationsContext";
import { useErrorUpdate } from "../Persistence/ErrorContext";
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";


const CLASSNAME = 'AssociatedContactsDataview'

export const AssociatedContactsDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.ASSOCIATEDCONTACTS);
	const addBreadcrumb = useBreadcrumbsAdd();
	const errorUpdate = useErrorUpdate();
	const configurationsList = useConfigurationsList();
	const isReader = useCurrentUserIsReader();
	const associatedContactsList = useAssociatedContactsList();
	const [displayAssociatedContactDialog, setDisplayAssociatedContactDialog] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1') {
			errorUpdate('Modul Netzwerkkontakte', 'Das Modul Netzwerkkontakte ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	}, [])

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'associatedContactsList', associatedContactsList
		)
	}, [associatedContactsList])

	const patchAssociatedContact = async () => {
		await patchData(ENUM_SERVICES.ASSOCIATEDCONTACTS, formikAssociatedContact.values).then((associatedContact) => {
			setDisplayAssociatedContactDialog(false)
			addBreadcrumb(`${associatedContact.name}`, `${ENUM_ROUTES.ASSOCIATEDCONTACTS}/` + associatedContact.id);
		})
	}

	const formikAssociatedContact = useFormik({
		initialValues: getEmptyAssociatedContact(),
		validate: validateAssociatedContact,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const onEditAssociatedContact = async (id) => {
		await getData(ENUM_SERVICES.ASSOCIATEDCONTACTS, id).then((associatedContact) => {
			logDefault(CLASSNAME, 'info', 'onEditOrganization organization:', associatedContact)
			formikAssociatedContact.setValues(associatedContact);
			setDisplayAssociatedContactDialog(true)
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Netzwerkkontakt bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => { onEditAssociatedContact(rowData.id) } }]

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const columnsAssociatedContacts = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", style: { width: '100px' }, body: null },
		{ field: "name", sortable: true, filter: "name", header: "Name", style: { flexGrow: 1, flexBasis: '200px' } },
		{ field: "organization", sortable: true, filter: "organization", header: "Organisation", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ field: "email", sortable: true, filter: "email", header: "E-Mail", style: { flexGrow: 1, flexBasis: '200px' } },
		{ field: "region.name", sortable: true, filter: "region.name", header: "Region", body: (rowData) => { return rowData.region ? rowData.region.name : '' }, style: { flexGrow: 1, flexBasis: '200px' }, },
	]

	return <div className='mx-auto eaa-dataView select-none' style={{ maxWidth: 'calc(100vw - 100px)' }}>
		<EditDialog
			form={<AssociatedContactForm formik={formikAssociatedContact} key='associatedcontact-form' />}
			formik={formikAssociatedContact}
			patchForm={patchAssociatedContact}
			header={formikAssociatedContact.values.id ? 'Netzwerkkontakt ändern' : 'Netzwerkkontakt neu anlegen'}
			message={formikAssociatedContact.values.id ? 'Hier können sie den Netzwerkkontakt: ' + formikAssociatedContact.values.name + ' ändern.' : 'Hier können sie einen Netzwerkkontakt neu anlegen'}
			setDisplayDialog={setDisplayAssociatedContactDialog}
			displayDialog={displayAssociatedContactDialog}
		/>

		<TemplateDataView
			key='datatable-associatedcontacts'
			showOpenLinkColumn={true}
			dataTableList={associatedContactsList}
			columns={columnsAssociatedContacts}
			DATAVIEW_CONFIG={DATAVIEW_CONFIG}
			buttons={[<Button key='button-new-associatedcontact' disabled={isReader} label='Netzwerkkontakt anlegen' onClick={() => { formikAssociatedContact.setValues(getEmptyAssociatedContact); setDisplayAssociatedContactDialog(true) }} />]}
			ROUTE={ENUM_ROUTES.ASSOCIATEDCONTACTS} />
	</div>
}