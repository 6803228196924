import { useEffect, useState } from 'react';
import { TemplateDataView } from '../Components/TemplateDataView';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useEAACasesInit, useEAACasesList } from '../Persistence/EAACasesContext';
import { EAACaseCreateWizard } from './EAACaseCreateWizard';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useDataviewsConfig } from '../Persistence/ConfigurationsContext';

export const EAACaseDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.EAACASES);
	const eaaCasesList = useEAACasesList();
	const initEaaCasesList = useEAACasesInit();

	const [dataviewList, setDataviewList] = useState([])

	useEffect(() => {
		initEaaCasesList();
	}, [])

	useEffect(() => {
		setDataviewList(eaaCasesList);
	}, [eaaCasesList])

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: true, header: "ID", style: { width: '100px' }, className: '', body: null },
		{ field: "responsible", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { flexGrow: 1, flexBasis: '200px' }, className: '', body: renderResponsible },
		{ field: "company.name", sortable: true, filter: true, header: "Unternehmen", style: { flexGrow: 1, flexBasis: '200px' }, className: '', body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ field: "name", sortable: true, filter: true, header: "Name", style: { flexGrow: 1, flexBasis: '250px' }, className: '' },
		{ field: "millisecondsCreatedAt", sortable: true, filter: true, header: "Falldatum", style: { width: '180px' }, className: '', body: 'renderDate' },
		{ field: "status", sortable: true, filter: true, header: "Status", style: { width: '200px' }, className: '', body: 'renderTag' }
	]

	return (
		<div className='mx-auto eaa-dataView ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<TemplateDataView
				columns={columns}
				showOpenLinkColumn={true}
				dataTableList={dataviewList}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
				buttons={[<EAACaseCreateWizard />]}
				ROUTE={ENUM_ROUTES.EAACASES} />
		</div>
	)

}