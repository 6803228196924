import { useFormik } from 'formik';
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useOrganizationsSelectionList } from '../Persistence/OrganizationsContext';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useUserSelectionList } from '../Persistence/UserContext';

export const emptyRelatedUser = { id: null, version: 1, active: 1, user: null, roleType: '', selectionUser: null }

export const RelatedUsersForm = ({ relatedUser, setRelatedUser, setShowDialog, listRelatedUsers, setFormHasErrors, parentId }) => {

	const configurationsList = useConfigurationsList();
	const userList = useUserSelectionList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const [organization, setOrganization] = useState(null);
	const [relatedUserList, setRelatedUserList] = useState([]);

	useEffect(() => {
		if (organizationsSelectionList && organizationsSelectionList.length === 1) {
			setOrganization(organizationsSelectionList[0])
		}
	}, [organizationsSelectionList])

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.organizationId === organization.id) : userList;
		if (relatedUser && relatedUser.id && relatedUser.user) {
			relatedUser.user.nameInList = relatedUser.user.displayname;
			setRelatedUserList([relatedUser.user]);
		} else {
			formik.setFieldValue('user', null)
			setRelatedUserList(tempList);
		}
	}, [organization, userList])


	const formik = useFormik({

		initialValues: {
			id: null, version: 1, active: 1, user: null, roleType: '', selectionUser: null
		},
		validate: (data) => {
			let errors = {};

			if (!data.roleType) {
				errors.roleType = 'Rolle muss gesetzt sein';
			}
			if (!data.user) {
				errors.user = 'Fachberater*in muss gesetzt sein';
			}
			if (data.user && data.user.permissions === 'READER' && data.roleType && data.roleType.alias === 'R') {
				errors.user = 'Eine Verantwortliche Rolle kann nicht durch eine/n Leser*in eingenommen werden!';
			}
			if (data.user && data.roleType && data.roleType.alias === 'R') {
				const existingRelatedUser = listRelatedUsers.find(entry => (entry.roleType === data.roleType.alias || entry.roleType.alias === data.roleType.alias));
				if (existingRelatedUser && existingRelatedUser.user.id !== data.user.id)
					errors.roleType = 'Verantwortliche Rolle bereits definiert';
			}
			if (data.user) {
				const existingRelatedUser = listRelatedUsers.find(entry => entry.user.id === data.user.id);
				if (existingRelatedUser && !relatedUser.id)
					errors.user = 'Fachberater*in hat bereits eine Rolle im Fall';
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null

			setFormHasErrors(hasErrors);
			setRelatedUser(data);
			return errors;
		},

		enableReinitialze: true,
		validateOnMount: true
	});

	useEffect(() => {
		formik.setValues(relatedUser);
	}, [])

	const onRelatedUserChange = (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			formik.setFieldValue('selectionUser', e.value.displayname)
			formik.setFieldValue('user', e.value)
		} else {
			formik.setFieldValue('user', null)
			formik.setFieldValue('selectionUser', e.value)
		}
	}

	return (
		<div className='col'>
			<div className="formgrid grid">
				<div className="field col">
					<div className='card mb-1'><div className="font-bold">Fachberater*innen</div> </div>
					<div className={relatedUser && parentId ? 'card' : 'hidden'}>
						<div className={(relatedUser.id ? 'hidden' : '') + " field col mr-3 "}>
							<label htmlFor='organization' className='block'>Auswahl Träger</label>
							<Dropdown id="organization" showClear filter name="organization" optionLabel="name" value={organization} disabled={organizationsSelectionList.length === 1} options={organizationsSelectionList} onChange={(e) => setOrganization(e.value)} autoFocus className={'bg-white w-full'} />
						</div>
						<DropdownChangesChecked filter={true} editmode={!relatedUser || !relatedUser.id} label={relatedUser && relatedUser.id ? 'Bei hinterlegten Fachberater*innen kann nur die Rolle geändert werden.' : 'Fachberater*in'} id={'user'} itemLabel={'nameInList'} formik={formik} options={relatedUserList} setShowDialog={setShowDialog} handleChange={onRelatedUserChange} />
						<DropdownChangesChecked editmode={true} label={'Rolle'} id={'roleType'} itemLabel={'display'} formik={formik} options={configurationsList.CaseRoleList} setShowDialog={setShowDialog} />
					</div>
				</div>
			</div>
		</div>


	)
}
