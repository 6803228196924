import { useState, useEffect } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { getSortedList } from '../snippets';
import { ENUM_ICONS } from '../Components/Icons';
import { LoadingMessageDataView } from '../Components/LoadingMessageDataView';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { format, parse } from 'date-fns';
import { useCaseEventsCountRecords, useCaseEventsInit, useCaseEventsList } from '../Persistence/CaseEventsContext';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';

const CLASSNAME = 'OverviewCaseEventsDataview'

export const OverviewCaseEventsDataview = () => {
	const configurationsList = useConfigurationsList()
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS);
	const isAdmin = useCurrentUserIsAdmin();
	const caseEventsInit = useCaseEventsInit();
	const caseEventsList = useCaseEventsList();
	const caseEventsCountRecords = useCaseEventsCountRecords();
	const [eventClassifierStructure, setEventClassifierStructure] = useState([]);
	const [eaaCaseEventsList, setEaaCaseEventsList] = useState();
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setCountRecords(caseEventsCountRecords);
	}, [caseEventsCountRecords])

	useEffect(() => {
		caseEventsInit();
	}, [])

	useEffect(() => {
		if (configurationsList.CaseEventClassifierStructure) {
			setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		}
	}, [configurationsList])

	useEffect(() => {
		if (mounted) {
			setEaaCaseEventsList(caseEventsList);
		}
	}, [mounted, caseEventsList])


	useEffect(() => {
		setMounted(true)
	}, [eventClassifierStructure])

	const renderEventtype = (rowData) => {
		const eventType = !configurationsList ? undefined : configurationsList.EventTypeList.find(e => e.alias === rowData.eventType)
		return eventType ? eventType.display : rowData.eventType
	}

	const renderResponsible = (rowData) => {
		return (rowData.creator ? rowData.creator.lastname + ', ' + rowData.creator.firstname : 'n.n.')
	}

	const renderClassifiers = (rowData) => {
		const eventClassifiersList = rowData.eventClassifiers;

		const classifierStructure = JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)).map((structure) => {
			const items = structure.items.filter(item => eventClassifiersList.includes(item.caseEventClassifier));
			return { ...structure, items }
		}).filter(structure => structure.items.length > 0)

		return <div className='flex flex-column' key={'div-classifier_' + rowData.id} >
			{classifierStructure.map((structure) => {
				const items = getSortedList(structure.items, 'caseEventClassifier', false);
				return <div key={'div_' + structure.headline + '_' + rowData.id}>
					<div className='flex p-0 m-0 text-600 text-base font-semibold' key={'classifier_' + structure.headline}>
						{structure.headline}
					</div>

					{items.map((item) => {
						const caseEventClassifierOption = configurationsList.CaseEventClassifierList.find(e => e.caseEventClassifier === item.caseEventClassifier);
						return <div className={caseEventClassifierOption.hide === 1 ? 'hidden' : 'flex'} key={'classifier_' + item.caseEventClassifier}>
							<div className='mr-1'><i className={ENUM_ICONS.CHECK_SQUARE}></i></div>
							<div className=''>{item.dataview} </div>
						</div>

					})}

				</div>
			})}
		</div>

	}
	const columnsCaseEvents = [
		{ field: "eaaCase.displayId", sortable: true, filter: true, header: "ID", style: { width: '100px' } },
		{ field: "eaaCase.name", sortable: true, filter: true, header: "Name des Falls", style: { flexGrow: 1, flexBasis: '150px' } },
		{ field: "millisecondsEventDate", sortable: true, filter: true, header: "Kontaktdatum", style: { width: '180px' }, body: 'renderDate' },
		{ field: "company.name", sortable: true, filter: true, header: "Name des Unternehmens", style: { flexGrow: 1, flexBasis: '150px' }, className: '', body: (rowData) => { return (rowData.company) ? rowData.company.name : '' } },
		{ field: "creator.id", className: (isAdmin ? '' : 'hidden'), sortable: true, sortField: 'creator.lastname', filter: true, header: "Ersteller*in", style: { width: '250px' }, body: renderResponsible },
		{ field: "eventType", sortable: true, filter: true, header: "Art des Kontaktes", style: { width: '200px' }, body: renderEventtype },
		{ field: "caseRequests.amountRequests", sortable: true, filter: true, header: "Anzahl der Anträge", style: { width: '130px' } },
		{ field: "eventClassifierIds", sortable: true, filter: true, filterField: 'eventClassifiers', header: "Thema", style: { width: '350px' }, body: renderClassifiers },
	]

	const exportFunction = (entry) => {
		const eventClassifier = entry.eventClassifiers || []
		const eventClassifiersCount = entry.eventClassifiersCount || []
		const value = {
			'Fall-ID': entry.eaaCase.displayId,
			'Name des Falls': entry.eaaCase.name,
			'Erstelldatum des Falls': format(parse(entry.eaaCase.createdAt, 'yyyy-MM-dd HH:mm:ss.SSS xxx', new Date()), 'yyyy-MM-dd'),
			'Fall-Datum': format(parse(entry.eaaCase.date, 'yyyy-MM-dd HH:mm:ss.SSS xxx', new Date()), 'yyyy-MM-dd'),
			'Status des Falls': configurationsList.CaseStatusList.find(e => e.alias === entry.eaaCase.status).display,
			'Kontaktdatum': format(entry.millisecondsEventDate, 'yyyy-MM-dd'),
			'Name des Unternehmen': entry.company.name,
			'Ersteller*in': entry.creator.lastname + ', ' + entry.creator.firstname,
			'Art des Kontaktes': configurationsList.EventTypeList.find(e => e.alias === entry.eventType).display,
			'Anzahl der Anträge': entry.caseRequests.amountRequests
		}

		configurationsList.CaseEventClassifierList.forEach((item) => {
			const classifier = eventClassifier.findIndex((e) => e === item.caseEventClassifier);
			const count = classifier !== undefined ? eventClassifiersCount[classifier] : ''
			value[item.prefix + item.dataview] = count
		})
		return value
	}

	return <div className='w-full overflow-auto'>
		<div className=''>
			<TemplateDataView
				showOpenLinkColumn={true}
				key='datatable-overviews-caserequests'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				dataTableList={eaaCaseEventsList}
				ROUTE={ENUM_ROUTES.EAACASES}
				routeIdField='eaaCaseId'
				columns={columnsCaseEvents}
				loadingMessage={<LoadingMessageDataView countRecords={countRecords} />}
				dataTableWidth='100%'
				exportFunction={exportFunction}
				exportColumns={46}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG} />
		</div>
	</div>
}