import { useEffect, useState } from "react";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked"
import { InputText } from 'primereact/inputtext';
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { InputChangesChecked } from "../Components/InputChangesChecked";

export const AssociatedContactForm = ({ formik }) => {
	const [regionsSelection, setRegionsSelection] = useState([]);
	const [editmode, setEditmode] = useState(true);
	const configurationsList = useConfigurationsList();
	const regionsList = useRegionsList();
	const regionsSelectionList = useRegionsSelectionList();

	const createRegionSelection = () => {
		const regionSelection = regionsSelectionList ? [...regionsSelectionList] : [];
		if (formik.values.regionId) {
			const region = regionsList.find(entry => entry.id === formik.values.regionId)
			if (region && !region.active) {
				regionSelection.push(region);
			}
		}
		setRegionsSelection(regionSelection)
	}
	useEffect(() => {
		createRegionSelection();
	}, [regionsSelectionList])

	return (<>
		<div className="formgrid grid ">
			<InputChangesChecked autoFocus={true} formik={formik} id='organization' label='Organisation' type='text' />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={6} formik={formik} id='name' label='Name' type='text' />
			<InputChangesChecked tabIndex={2} formik={formik} id='phone' label='Telefon' type='text' />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={3} formik={formik} id='mobile' label='Mobil' type='text' />
			<InputChangesChecked tabIndex={4} formik={formik} id='email' label='E-Mail' type='text' />
		</div>
		<div className="formgrid grid">
			<DropdownChangesChecked tabIndex={5} label={'Status'} id='active' editmode={true} itemLabel={'display'} optionValue='alias' formik={formik} options={configurationsList.StatusActiveList} />
			<DropdownChangesChecked tabIndex={7} options={regionsSelection} editmode={true} formik={formik} id={'regionId'} itemLabel={'name'} optionValue='id' label={'EAA Region'} onChange={createRegionSelection} />
		</div>
		<div className="field">
			<label >Adresse</label>
			<div className="p-3 border-1 surface-border mr-3">
				<div className="field grid">
					<label htmlFor="line1" className="col-fixed w-5rem">Zeile 1:</label>
					<div className="col">
						<InputText tabIndex={8} className='w-full' value={formik.values.line1} id='line1' onChange={(e) => formik.setFieldValue('line1', e.target.value)} type='text' />
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="line2" className="col-fixed w-5rem">Zeile 2:</label>
					<div className="col">
						<InputText tabIndex={9} className='w-full' value={formik.values.line2} id='line2' onChange={(e) => formik.setFieldValue('line2', e.target.value)} type='text' />
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="line3" className="col-fixed w-5rem">Zeile 3:</label>
					<div className="col">
						<InputText tabIndex={10} className='w-full' value={formik.values.line3} id='line3' onChange={(e) => formik.setFieldValue('line3', e.target.value)} type='text' />
					</div>
				</div>
				<div className="flex m-0">
					<div className="flex col-3 field p-0">
						<label htmlFor="postcode" className=" p-0 col-fixed w-5rem">PLZ:</label>
						<div className="col p-0 ">
							<InputText tabIndex={11} className='w-full' value={formik.values.postcode} id='postcode' onChange={(e) => formik.setFieldValue('postcode', e.target.value)} type='text' />
						</div>
					</div>
					<div className="flex col-9 field p-0 ">
						<label htmlFor="city" className="ml-4 p-0 col-fixed w-5rem">Ort:</label>
						<div className="col p-0 ">
							<InputText tabIndex={12} className='w-full' value={formik.values.city} id='city' onChange={(e) => formik.setFieldValue('city', e.target.value)} type='text' />
						</div>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="country" className="col-fixed w-5rem">Land:</label>
					<div className="col">
						<InputText tabIndex={13} className='w-full' value={formik.values.country} id='line3' onChange={(e) => formik.setFieldValue('country', e.target.value)} type='text' />
					</div>
				</div>
			</div>
		</div>
		<div className="formgrid grid ">
			<InputChangesChecked tabIndex={14} formik={formik} id='description' label='Kurznotiz' type='text' />
		</div>
	</>
	)
}
export const getEmptyAssociatedContact = () => { return { id: null, active: 1, version: 1, name: '', description: '', line1: '', line2: '', line3: '', email: '', mobile: '', phone: '', regionId: null, postcode: '', city: '' } }
export const validateAssociatedContact = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	if (!data.organization) {
		errors.organization = 'Organisation ist ein Pflichtfeld';
	}
	return errors;
}
