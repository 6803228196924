import React, { useContext, useState, useEffect } from "react";
import { updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { fetchDataWithTimeout } from "../snippets";
import { readOverviewData } from "../Overviews/OverviewManager";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'CompaniesProvider';
const servicename = 'companies';
const CompaniesContext = React.createContext();
const CompaniesInitContext = React.createContext();

export function useCompaniesList() {
	return useContext(CompaniesContext);
}
export function useCompaniesInit() {
	return useContext(CompaniesInitContext);
}

export function CompaniesProvider({ children }) {
	const [list, setList] = useState();
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	const initProvider = () => {
		if (!mounted) {
			setMounted(true)
		}
	}

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', 'ListItemChanged', listItemChanged)
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => await readOverviewData(ENUM_DATAVIEWS_CONFIG.COMPANIES, 100, setList, null), 1)
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<CompaniesInitContext.Provider value={initProvider}>
			<CompaniesContext.Provider value={list}>
				{children}
			</CompaniesContext.Provider>
		</CompaniesInitContext.Provider>
	)
}