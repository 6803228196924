import React, { useContext, useState, useEffect, useCallback } from "react";
import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import client, { getData } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { reactLocalStorage } from 'reactjs-localstorage';
import { useTaskareasList } from "./TaskareasContext";
import { useRegionsList } from "./RegionsContext";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { format } from "date-fns";

const providername = 'ConfigurationsProvider';

const ConfigurationsContext = React.createContext();
const ConfigurationsCompleteContext = React.createContext();
const DataviewsConfigContext = React.createContext();
const FormatDateContext = React.createContext();
const FormatDateTimeContext = React.createContext();

const IgnoreInStatisticesList = [
	{ icon: ENUM_ICONS.STATISTICS, display: 'Relevant', datatable: 'Relevant', alias: 0 },
	{ icon: ENUM_ICONS.CROSS + ' text-red-600', display: 'Ignorieren', datatable: 'Ignorieren', alias: 1 }
]
const ServiceList = [
	{ awesomeIcon: ENUM_AWESOMEICON.EAACASE, onlyIcon: true, datatable: 'Fall', alias: ENUM_SERVICES.EAACASES, visible: true, functionBadge: (rowData) => rowData.reminded === -1 },
	{ awesomeIcon: ENUM_AWESOMEICON.COMPANY, onlyIcon: true, datatable: 'Unternehmen', alias: ENUM_SERVICES.COMPANIES, visible: true, functionBadge: (rowData) => rowData.reminded === -1 },
	{ awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, onlyIcon: true, datatable: 'Trägeraktivitäten ', alias: ENUM_SERVICES.PROVIDERACTIVITIES, visible: true, functionBadge: (rowData) => rowData.reminded === -1 },
	{ awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, onlyIcon: true, datatable: 'Netzwerkkontakt', alias: ENUM_SERVICES.ASSOCIATEDCONTACTS, visible: true, functionBadge: (rowData) => rowData.reminded === -1 },
	{ awesomeIcon: ENUM_AWESOMEICON.JOBPROFILE, onlyIcon: true, datatable: 'Stellenprofil', alias: ENUM_SERVICES.JOBPROFILES, visible: true, functionBadge: (rowData) => rowData.reminded === -1 },

]
const AssociatedContactsServiceList = [
	{ icon: ENUM_ICONS.EAACASE, onlyIcon: true, datatable: 'Fall', alias: ENUM_SERVICES.EAACASES },
	{ icon: ENUM_ICONS.COMPANY, onlyIcon: true, datatable: 'Unternehmen', alias: ENUM_SERVICES.COMPANIES },
	{ awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, onlyIcon: true, datatable: 'Trägeraktivitäten ', alias: ENUM_SERVICES.PROVIDERACTIVITIES },
]
const StatusActiveList = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', datatable: 'Aktiv', alias: 1, color: 'green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', datatable: 'Inaktiv', alias: 0, color: 'grey' }];
const StatusAvailableList = [{ icon: 'fa fa-check-circle-o ', display: 'Ja', datatable: 'Ja', alias: 1, color: 'green' }, { icon: 'fa fa-times-circle-o', display: 'Nein', datatable: 'Nein', alias: 0, color: 'grey' }];
const EaaCaseReviewList = [
	{ id: 'NOG1', icon: 'fa fa-check-circle-o ', name: 'kein Betriebsbesuch', display: 'kein Betriebsbesuch', datatable: 'Betriebsbesuch fehlt', alias: 'NOG1', color: 'blue' },
	{ id: 'NOCR', icon: 'fa fa-times-circle-o', name: 'kein Ergebnis', display: 'kein Ergebnis', datatable: 'Ergebnis fehlt', alias: 'NOCR', color: 'blue' },
	{ id: 'NOCS', icon: 'fa fa-times-circle-o', name: 'keine Nachhaltigkeit', display: 'keine Nachhaltigkeit', datatable: 'Nachhaltigkeit fehlt', alias: 'NOCS', color: 'blue' },
	{ id: 'S9_INPROGRESS', icon: 'fa fa-times-circle-o', name: 'Offene Anträge', display: 'Offene Anträge', datatable: 'Offene Anträge', alias: 'S9_INPROGRESS', color: 'blue' }
];

export function useConfigurationsList() {
	return useContext(ConfigurationsContext);
}
export function useDataviewsConfig() {
	return useContext(DataviewsConfigContext);
}
export function useConfigurationsComplete() {
	return useContext(ConfigurationsCompleteContext);
}
export function useFormatDate() {
	return useContext(FormatDateContext);
}
export function useFormatDateTime() {
	return useContext(FormatDateTimeContext);
}
export function ConfigurationsProvider({ children }) {
	const taskareasList = useTaskareasList();
	const regionsList = useRegionsList();
	const [enumsList, setConfigurationsList] = useState();
	const [completed, setCompleted] = useState(false);
	const [dateFormat, setDateFormat] = useState('yyyy-MM-dd');
	const [dateTimeFormat, setDateTimeFormat] = useState('yyyy-MM-dd HH:ss');

	const getDataviewConfig = (alias) => {
		for (let key of Object.keys(enumsList.DataviewsConfig)) {
			const dataviewsConfig = enumsList.DataviewsConfig[key]
			if (dataviewsConfig.alias === alias) {
				return dataviewsConfig
			}
		}
	}
	const getFormatDate = useCallback((date) => {
		return format(date || new Date(), dateFormat)
	}, [dateFormat])
	const getFormatDateTime = useCallback((date) => {
		return format(date || new Date(), dateTimeFormat)
	}, [dateTimeFormat])

	useEffect(() => {
		if (taskareasList && regionsList) {
			const config = reactLocalStorage.get('OVERVIEWS', '{}')
			client.service('utils').create({ method: 'updateConfig', config }).then(() => {
				getData('configurations').then((_list) => {
					if (reactLocalStorage.get('eaaModules.REQUESTDISTRIBUTION', "1") === "0") {
						_list.eaaModules.REQUESTDISTRIBUTION = false
					} else if (_list.eaaModules.REQUESTDISTRIBUTION) {
						if (_list.eaaModules.REQUESTDISTRIBUTION === "0") {
							_list.eaaModules.REQUESTDISTRIBUTION = false
						} else {
							_list.eaaModules.REQUESTDISTRIBUTION = true
						}

					}
					_list.StatusActiveList = StatusActiveList;
					_list.StatusAvailableList = StatusAvailableList;
					_list.IgnoreInStatisticesList = IgnoreInStatisticesList;
					_list.EaaCaseReviewList = EaaCaseReviewList;
					_list.ServiceList = ServiceList;
					if (_list.eaaModules.JOBOPENING !== '1') {
						_list.ServiceList = _list.ServiceList.filter(e => e.alias !== ENUM_SERVICES.JOBPROFILES)
					}
					if (_list.eaaModules.ASSOCIATEDCONTACTS !== '1') {
						_list.ServiceList = _list.ServiceList.filter(e => e.alias !== ENUM_SERVICES.ASSOCIATEDCONTACTS)
					}
					_list.AssociatedContactsServiceList = AssociatedContactsServiceList;
					setConfigurationsList({ ..._list, TaskareasList: taskareasList, RegionsList: regionsList });
					logContext(providername, 'info', `########### ${providername} useEffect found:${Object.getOwnPropertyNames(_list).length}`)
					setCompleted(true)
					setDateFormat(_list.formatDate || 'yyyy-MM-dd')
					setDateTimeFormat(_list.formatDateTime || 'yyyy-MM-dd HH:ss')
				}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			})
		}

	}, [taskareasList, regionsList])

	useEffect(() => {
		const _list = enumsList ? { ...enumsList, TaskareasList: taskareasList } : { TaskareasList: taskareasList }
		logContext(providername, 'info', `########### ${providername} update taskareasList `, (_list.TaskareasList.length))
		setConfigurationsList(_list);
	}, [taskareasList])

	useEffect(() => {
		const _list = enumsList ? { ...enumsList, RegionsList: regionsList } : { RegionsList: regionsList }
		logContext(providername, 'info', `########### ${providername} update regionsList `, (_list.RegionsList.length))
		setConfigurationsList(_list);
	}, [regionsList])

	return (
		<ConfigurationsContext.Provider value={enumsList}>
			<FormatDateContext.Provider value={getFormatDate}>
				<FormatDateTimeContext.Provider value={getFormatDateTime}>
					<ConfigurationsCompleteContext.Provider value={completed}>
						<DataviewsConfigContext.Provider value={getDataviewConfig}>
							{children}
						</DataviewsConfigContext.Provider>
					</ConfigurationsCompleteContext.Provider>
				</FormatDateTimeContext.Provider>
			</FormatDateContext.Provider>
		</ConfigurationsContext.Provider>
	)
}