import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { createPassword, getData, patchData } from '../feathers';
import { ChangePassword } from '../Login/ChangePassword';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList, useDataviewsConfig, useFormatDate } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsSuperAdmin, useUserSwitchMode, useUserSwitchModeUpdate } from '../Persistence/CurrentUserContext';
import { useTaskareasList } from '../Persistence/TaskareasContext'
import { useUserList } from "../Persistence/UserContext";
import { emptyUser, UserForm, validateUser } from './UserForm';
import { UserProfile } from './UserProfile';
import { useFormik } from 'formik';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { TemplateDataView } from '../Components/TemplateDataView';
import { useRegionsList } from "../Persistence/RegionsContext";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { getSortedList } from "../snippets";
import { format, parseISO } from "date-fns";
import { Tooltip } from 'primereact/tooltip';


export const UserAdministration = () => {
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.USERADMINISTRATION);
	const formatDate = useFormatDate();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin()
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const setUserSwitchMode = useUserSwitchModeUpdate();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const usersList = useUserList();
	const taskareasList = useTaskareasList();
	const regionsList = useRegionsList();
	const [user, setUser] = useState();
	const [displayUserDialog, setDisplayUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!isAdmin && !userSwitchMode) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [currentUser, userSwitchMode, navigate])

	useEffect(() => {

	}, [usersList])

	const handleHidePasswordDialog = () => {
		setDisplayPasswordDialog(false);
	}

	const getUser = async (id) => {
		await getData('users', id).then((user) => {
			user.permissions = configurationsList.RolesList.find((entry) => entry.alias === user.permissions)
			user.mobilenumber = user.mobilenumber || '';
			user.phonenumber = user.phonenumber || '';
			user.addToRecipientList = user.addToRecipientList || 0;
			user.regions = user.regions.map(e => regionsList.find(region => e.id === region.id));
			user.taskareas = user.taskareas.map(e => taskareasList.find(taskarea => e.id === taskarea.id))
			user.userFTEs = user.userFTEs.map((userFTE) => { userFTE.date = parseISO(userFTE.date); return userFTE })
			user.userFTEs = getSortedList(user.userFTEs, 'date', true)

			user.dateLastFTE = user.userFTEs.length > 0 ? user.userFTEs[0].date : null
			user.lastFTE = user.userFTEs.length > 0 ? user.userFTEs[0].fte : null
			formikUser.setValues(user)
			setUser(user);
		})
	}

	const handleSelectUser = async (id) => {
		await getUser(id)
		setDisplayUserDialog(true);
	}

	const handleChangePassword = async (id) => {
		await getData('users', id).then((_user) => {
			setUser(_user);
			setDisplayPasswordDialog(true);
		})
	}
	const handleChangeUser = async (id) => {
		await getData('users', id).then((user) => {
			setUserSwitchMode(user);
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Nutzer*in ändern', icon: ENUM_ICONS.PENCIL, command: () => handleSelectUser(rowData.id), disabled: currentUser.permissions === 'READER' },];
		if (rowData.id !== currentUser.id) {
			items.push({ label: 'Passwort ändern', icon: ENUM_ICONS.PENCIL, command: () => handleChangePassword(rowData.id) });
		}

		if (rowData.active === 1) {
			items.push({ label: 'Nutzer*in inaktiv setzen', icon: ENUM_ICONS.CROSS, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		} else {
			items.push({ label: 'Nutzer*in aktiv setzen', icon: ENUM_ICONS.CHECK, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		}
		if (currentUser.isAdmin || userSwitchMode) {
			items.push({ label: 'Identität annehmen', icon: ENUM_ICONS.USER, command: () => handleChangeUser(rowData.id) });
		}

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const patchUser = async () => {
		//formikUser.values.organizationId = formikUser.values.organization ? formikUser.values.organization.id : null;
		formikUser.values.permissions = formikUser.values.permissions.alias
		formikUser.values.displayname = formikUser.values.firstname + " " + formikUser.values.lastname
		const user = formikUser.values
		let patchedUser = user;
		if (user.id) {
			delete user.password
		} else {
			patchedUser = await patchData('users', user)
		}
		for (let entryFTE of user.userFTEs) {
			await patchData('user-fte', { ...entryFTE, userId: patchedUser.id })
		}
		await patchData('users', patchedUser).then(async () => {
			setDisplayUserDialog(false);
		})
	}

	const onClickNewUser = () => {
		formikUser.setValues({ ...emptyUser, password: createPassword(false) })
		setDisplayUserDialog(true);
	}

	const formikUser = useFormik({
		initialValues: { ...emptyUser },
		validate: validateUser,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderEmail = (row) => {
		return <div className='flex max-w-1rem'><a href={`mailto:${row.email}`}>{row.email}</a></div>
	}
	const renderRecipients = (rowData) => {
		return rowData.addToRecipientList === 1 ? 'ja' : 'nein'
	}
	const renderLoginAllowed = (rowData) => {
		return rowData.loginAllowed === 1 ? 'ja' : 'nein'
	}
	const renderFTE = (rowData) => {
		return <div className='w-full' >
			<Tooltip target={'.lastFTE_' + rowData.id} content={`seit: ${formatDate(rowData.dateLastFTE)}`} />
			<div className={'lastFTE_' + rowData.id} >{rowData.lastFTE.toLocaleString('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
		</div>
	}

	const columns = [
		{ field: "id", sortable: true, header: "ID", style: { width: '70px' }, body: (row) => <div className='flex justify-content-end w-full'>{row.id}</div> },
		{ field: "organization.name", sortable: true, filter: "organization.name", header: "Träger", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "displayname", sortable: true, filter: "displayname", header: "Name", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ field: "permissions", sortable: true, filter: "permissions", header: "Rolle", style: { width: '220px' }, body: 'renderList' },
		{ field: "active", sortable: true, filter: "active", header: "Status", style: { width: '200px' }, body: 'renderTag' },
		{ field: "loginAllowed", sortable: false, filter: false, header: "Login", style: { width: '90px' }, body: renderLoginAllowed },
		{ field: "addToRecipientList", sortable: false, filter: false, header: "Verteiler", style: { width: '90px' }, body: renderRecipients },
		{ field: "lastFTE", sortable: true, filter: false, header: "FTE", style: { width: '70px' }, body: renderFTE },
		{ field: "email", filter: true, sortable: true, header: "E-Mail", style: { flexGrow: 1, flexBasis: '250px' }, body: renderEmail },
		{ sortable: false, style: { width: '30px' }, body: renderContextMenu },
	]

	const exportFunction = (entry) => {
		const value = {
			'Organisation': entry.organization.name,
			'Nachname': entry.lastname,
			'Vorname': entry.firstname,
			'E-Mail': entry.email,
			'Telefon': entry.phonenumber,
			'Mobiltelefon': entry.mobilenumber,
			'Rolle:': configurationsList.RolesList.find(e => e.alias === entry.permissions).display,
			'FTE': entry.fte,
			'In Verteiler': entry.addToRecipientList,
			'Status': entry.active ? 'aktiv' : 'inaktiv',
			'Login möglich': entry.loginAllowed === 1 ? 'ja' : 'nein',
			'In Verteiler': entry.addToRecipientList,
		}
		return value
	}

	return (
		<div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<ConfirmDialog title='Status ändern' message={'Nutzer: ' + (user ? user.lastname + ', ' + user.firstname : '') + (user && user.active === 0 ? ' aktivieren?' : ' deaktivieren?')} labelOk='Ja'
				handleOnClick={() => { user.active = (user.active === 1 ? 0 : 1); patchUser(user) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header={'Passwort ändern: ' + (user ? user.displayname : '')} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={handleHidePasswordDialog} hideLogo={true} />
			</Dialog>

			<EditDialog
				form={<UserForm formik={formikUser} key='user-form' />}
				formik={formikUser}
				patchForm={patchUser}
				header={formikUser.values.id ? 'Nutzerdaten ändern' : 'Neue/r Nutzer*in anlegen'}
				message={formikUser.values.id ? 'Hier können sie den/die Nutzer*in: ' + formikUser.values.displayname + ' ändern.' : undefined}
				setDisplayDialog={setDisplayUserDialog}
				displayDialog={displayUserDialog}
			/>
			{currentUser.permissions !== 'ADMIN' && !userSwitchMode ?
				<UserProfile handleEditUser={handleSelectUser} handleChangePassword={handleChangePassword} /> :
				<div className='overflow-auto' style={{ maxWidth: 'calc(100vw - 100px)' }}>
					<TemplateDataView
						DATAVIEW_CONFIG={DATAVIEW_CONFIG}
						columns={columns}
						dataTableList={usersList}
						onDoubleClick={handleSelectUser}
						buttons={[<Button key={'button-new-user'} className='flex text-white' label='Nutzer*in anlegen' onClick={onClickNewUser} />, <Button key={'button-create-error'} className={isSuperAdmin ? 'flex  text-white' : 'hidden'} label='Fehler produzieren' onClick={() => { throw new Error('TEst Fehler') }} />]}
						exportFunction={exportFunction} />
				</div>
			}
		</div>
	)
}