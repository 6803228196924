import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { useDataviewsConfig } from '../Persistence/ConfigurationsContext';

const CLASSNAME = 'OverviewProviderActivitiesDataview'
const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES;
export const OverviewProviderActivitiesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const isAdmin = useCurrentUserIsAdmin();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES));

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	const columns = [
		{ field: "id", sortable: true, filter: true, header: "ID", style: { width: '100px' }, body: (rowData) => rowData.displayId },
		{ field: "name", sortable: true, filter: true, filterPlaceholder: "Name suchen", header: "Name der Trägeraktivität", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ field: "typeActivity", sortable: true, filter: "typeActivity", filterPlaceholder: "Name suchen", header: "Typ", style: { flexGrow: 1, flexBasis: '200px' }, body: 'renderList' },
		{ field: "responsible.id", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { flexGrow: 1, flexBasis: '200px' }, body: renderResponsible },
		{ field: "participants", sortable: true, filter: true, header: "Anzahl Teilnehmer", className: 'w-11rem', body: (rowData) => rowData.participants || '' },
		{ field: "date", sortable: true, filter: true, header: "Datum der Trägeraktivität", style: { width: '180px' }, body: (rowData) => rowData.formatted_date, },
		{ field: "endDate", sortable: true, filter: true, header: "Wiederkehrend bis", style: { width: '200px' }, body: (rowData) => rowData.formatted_enddate, }
	]

	return <div className='w-full overflow-auto'>
		<div className='' >
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-companyaddresses'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.PROVIDERACTIVITIES}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>}
		</div>
	</div>
}