import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

export enum ENUM_ROUTES {
	ERROR = 'Fehler',
	JOBPROFILES = 'Stellenprofile', STATISTICS = 'Statistik', PROVIDERACTIVITIES = 'Traegeraktivitaeten', COMPANIES = 'Unternehmen', REQUESTDISTRIBUTION = 'Aufgabenverteilung', REQUESTDISTRIBUTIONARCHIVE = 'Aufgabenverteilung-Archiv', REQUESTDISTRIBUTIONUSERRELATIONS = 'Aufgabenverteilung-Verwaltung',
	EAACASES = 'Faelle', DASHBOARD = 'Dashboard', ADDRESSES = 'Adressen', USERADMINISTRATION = 'Nutzer*innen', SETTINGS = 'Stammdaten', USERPROFILE = 'Profil', ASSOCIATEDCONTACTS = 'Netzwerkkontakte',
	OVERVIEWS = 'Uebersichten'
};

export const ROUTES = {
	ERROR: { label: 'Keine Berechtigung', icon: ENUM_ICONS.DEACTIVATE, route: ENUM_ROUTES.ERROR },
	JOBPROFILES: { label: 'Stellenprofile', icon: ENUM_ICONS.JOBPROFILE, route: ENUM_ROUTES.JOBPROFILES, service: 'job-openings', labelNew: '' },
	STATISTICS: { label: 'Statistik', icon: ENUM_ICONS.STATISTICS, route: ENUM_ROUTES.STATISTICS },
	PROVIDERACTIVITIES: { label: 'Trägeraktivitäten', awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, icon: ENUM_ICONS.PROVIDERACTIVITIES, route: ENUM_ROUTES.PROVIDERACTIVITIES, service: 'provideractivities' },
	COMPANIES: { label: 'Unternehmen', icon: ENUM_ICONS.COMPANY, route: ENUM_ROUTES.COMPANIES, service: 'companies' },
	EAACASES: { label: 'Fälle', icon: ENUM_ICONS.EAACASE, route: ENUM_ROUTES.EAACASES, service: 'eaa-cases', labelNew: 'Fall anlegen' },
	DASHBOARD: { label: 'Dashboard', icon: ENUM_ICONS.DASHBOARD, route: ENUM_ROUTES.DASHBOARD },
	ADDRESSES: { label: 'Adressen', icon: '', route: ENUM_ROUTES.ADDRESSES },
	USERADMINISTRATION: { label: 'Nutzer*innen', awesomeIcon: ENUM_AWESOMEICON.USERADMINISTRATION, icon: ENUM_ICONS.USERADMINISTRATION, route: ENUM_ROUTES.USERADMINISTRATION },
	SETTINGS: { label: 'Stammdaten', awesomeIcon: ENUM_AWESOMEICON.SETTINGS, icon: ENUM_ICONS.SETTINGS, route: ENUM_ROUTES.SETTINGS },
	USERPROFILE: { label: 'Mein Profil', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.USERPROFILE },
	REQUESTDISTRIBUTION: { label: 'Aufgabenverteilung', icon: ENUM_ICONS.REQUESTDISTRIBUTION, route: ENUM_ROUTES.REQUESTDISTRIBUTION, service: ENUM_SERVICES.REQUESTDISTRIBUTIONS },
	REQUESTDISTRIBUTIONARCHIVE: { label: 'Aufgabenverteilung Archiv', icon: ENUM_ICONS.REQUESTDISTRIBUTION, route: ENUM_ROUTES.REQUESTDISTRIBUTIONARCHIVE, service: ENUM_SERVICES.REQUESTDISTRIBUTIONS },
	REQUESTDISTRIBUTIONUSERRELATIONS: { label: 'Aufgabenverteilung Verwaltung Bearbeiter*innen', icon: ENUM_ICONS.REQUESTDISTRIBUTION, route: ENUM_ROUTES.REQUESTDISTRIBUTIONUSERRELATIONS },
	ASSOCIATEDCONTACTS: { label: 'Netzwerkkontakte', awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, icon: ENUM_ICONS.ASSOCIATEDCONTACTS, route: ENUM_ROUTES.ASSOCIATEDCONTACTS },
	OVERVIEWS: { label: 'Übersichten', awesomeIcon: ENUM_AWESOMEICON.OVERVIEWS, route: ENUM_ROUTES.OVERVIEWS },
}