import { useState, useEffect } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { useCaseRequestTypesSelectionList } from '../Persistence/CaseRequestTypesContext';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { readOverviewData } from './OverviewManager';
import { LoadingMessageDataView } from '../Components/LoadingMessageDataView';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { format } from 'date-fns';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';


const CLASSNAME = 'OverviewCaseRequestsDataview'

export const OverviewCaseRequestsDataview = () => {
	const configurationsList = useConfigurationsList();
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS);
	const caseRequestTypeList = useCaseRequestTypesSelectionList();
	const isAdmin = useCurrentUserIsAdmin();
	const [caseRequestsList, setCaseRequestsList] = useState();
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => await readOverviewData(DATAVIEW_CONFIG.alias, 50, setCaseRequestsList, setCountRecords), 1)
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderCaseRequestType = (rowData) => {
		return !caseRequestTypeList ? '' : (caseRequestTypeList.find(e => e.id === rowData.caseRequestTypeId) || { listentry: 'N.N.' }).listentry
	}

	const renderCreator = (rowData) => {
		return (rowData.creator ? rowData.creator.lastname + ', ' + rowData.creator.firstname : 'n.n.')
	}
	const columnsCaseRequests = [
		//{ field: "displayId", sortable: true, filter: true, header: "Antrags ID", style: { width: '100px' }, },
		{ field: "eaaCase.displayId", sortable: true, filter: true, header: "ID", style: { width: '100px' }, },
		{ field: "name", sortable: true, filter: "name", header: "Name des Falls", style: { flexGrow: 1, flexBasis: '150px' } },
		{ field: "company.name", sortable: true, filter: true, header: "Name des Unternehmens", style: { flexGrow: 1, flexBasis: '150px' }, className: '' },
		{ field: "creator", sortable: true, className: (isAdmin ? '' : 'hidden'), sortField: 'creator.lastname', filter: true, filterField: 'creator.id', header: "Ersteller*in", style: { width: '250px' }, body: renderCreator },
		{ field: "caseRequestTypeId", sortable: true, filter: true, header: "Art des Antrags", style: { width: '350px' }, body: renderCaseRequestType },
		{ field: "status", sortable: true, filter: true, header: "Status des Antrags", style: { width: '160px' }, body: 'renderTag' },
		{ field: "millisecondsRequestDate", sortable: true, filter: true, header: "Antragsdatum", style: { width: '180px' }, body: 'renderDate' },
	]

	const exportFunction = (entry) => {
		const value = {
			'Fall-ID': entry.eaaCase.displayId,
			'Name des Falls': entry.name,
			'Unternehmen': entry.company.name,
			'Antrags ID': entry.displayId,
			'Art des Antrags': caseRequestTypeList.find(e => e.id === entry.caseRequestTypeId).name,
			'Ersteller': entry.creator.displayname,
			'Status': configurationsList.CaseRequestStatusList.find(e => e.alias === entry.status).display,
			'Antragsdatum': format(entry.millisecondsRequestDate, 'yyyy-MM-dd'),
			'Bescheiddatum': entry.millisecondsCompletionDate ? format(entry.millisecondsCompletionDate, 'yyyy-MM-dd') : '-'
		}
		return value
	}


	return <div className='flex flex-column gap-2'>
		<TemplateDataView
			showOpenLinkColumn={true}
			key='datatable-overviews-caserequests'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			dataTableList={caseRequestsList}
			ROUTE={ENUM_ROUTES.EAACASES}
			routeIdField='eaaCaseId'
			columns={columnsCaseRequests}
			loadingMessage={<LoadingMessageDataView countRecords={countRecords} />}
			dataTableWidth='100%'
			exportFunction={exportFunction}
			exportColumns={9}
			DATAVIEW_CONFIG={DATAVIEW_CONFIG}
		/>
	</div>
}