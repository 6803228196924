import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { deleteData, patchData } from "../feathers";
import { UserProvider } from "../Persistence/UserContext";
import { RelatedUserCard } from "./RelatedUserCard";
import { RelatedUsersForm, emptyRelatedUser } from "./RelatedUsersForm";
import { ToastError } from "../Utils/ToastError";
import { Carousel } from "primereact/carousel";
import { ENUM_ICONS } from "../Components/Icons";
import { useState } from "react";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { EAADivider } from "../Components/EAADivider";

export const RELATEDUSERSERVICECONFIG = {
	CASE: { servicename: ENUM_SERVICES.CASEUSERS, idFieldname: 'eaaCaseId', displayname: 'Falls', deleteMessage: 'vom Fall', editMessage: 'im Fall' },
	COMPANY: { servicename: ENUM_SERVICES.COMPANYUSERS, idFieldname: 'companyId', displayname: 'Unternehmens', deleteMessage: 'vom Unternehmen', editMessage: 'des Unternehmens' },
	PROVIDERACTIVITY: { servicename: ENUM_SERVICES.PROVIDERACTIVITYUSERS, idFieldname: 'provideractivityId', displayname: 'Trägeraktivität', deleteMessage: 'aus der Trägeraktivität', editMessage: 'der Trägeraktivität' },
}

export const sortRelatedUser = (relatedUsers) => {
	relatedUsers.sort((b, a) => {
		if (a.roleType.alias < b.roleType.alias) {
			return -1;
		}
		if (a.roleType.alias > b.roleType.alias) {
			return 1;
		}
		return 0;
	})
	return relatedUsers
}

export const RelatedUsersSheet = ({ relatedUsers, onRelatedUserPatched, readonly, relatedUserServcieConfig, parentId, hideDivider }) => {
	const [relatedUser, setRelatedUser] = useState();
	const [displayRelatedUserDialog, setDisplayRelatedUserDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [displayConfirmDialogResponsible, setDisplayConfirmDialogResponisble] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState(true);
	const [toastParams, setToastParams] = useState(null);

	const onRelatedUsersUpdate = async () => {
		relatedUser[relatedUserServcieConfig.idFieldname] = parentId;
		relatedUser.userId = relatedUser.user.id

		await patchData(relatedUserServcieConfig.servicename, relatedUser).then(async (patchedRelatedUser) => {
			await onRelatedUserPatched(patchedRelatedUser);
		}).catch((error) => {
			setToastParams({ title: 'Operative Rolle konnte nicht ' + (relatedUser.id ? 'geändert' : 'angelegt') + ' werden', message: error.message })
		});
		setDisplayRelatedUserDialog(false);
	}

	const onRelatedUserDelete = async () => {
		await deleteData(relatedUserServcieConfig.servicename, relatedUser.id).then(async () => {
			await onRelatedUserPatched();
			setRelatedUser(null);
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht gelöscht werden', message: error.message })
		});
	}

	const onRelatedUserEdit = (relatedUser) => {
		if (relatedUser) {
			relatedUser.selectionUser = relatedUser.user.displayname
			relatedUser.isResponsible = relatedUser.roleType.alias === 'R';
			setRelatedUser(relatedUser)
		} else {
			setRelatedUser({ ...emptyRelatedUser })
		}
		setDisplayRelatedUserDialog(true)
	}

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	const confirmRelatedUserDelete = async (relatedUser) => {
		setRelatedUser(relatedUser);
		setDisplayConfirmDialogDelete(true)
	}

	const confirmCaseUserUpdate = async () => {
		if (relatedUser.isResponsible && relatedUser.roleType.alias !== 'R') {
			/** Wenn der zu ändernde related-user der Verantwortliche war und jetzt nicht mehr
			* -> Abfrage ob der Verantwortliche wirklich 'geändert' werden soll */
			setDisplayConfirmDialogResponisble(true)
		} else {
			await onRelatedUsersUpdate()
		}
	}

	const renderHeader = () => {
		return <div className={(hideDivider ? 'flex' : 'hidden') + ' flex-column'}>
			<div className='flex font-bold mb-2'>Operative Rollen</div>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Fachberater*innen</div>
				<div className='flex '>
					<div className={readonly ? 'hidden' : 'flex w-1rem open-icon'} onClick={() => { onRelatedUserEdit(null); setDisplayRelatedUserDialog(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderRelatedUserCard = (relatedUser) => {
		return !relatedUser ? '' : <RelatedUserCard key={'relatedusercard_' + relatedUser.id} readonly={readonly} relatedUser={relatedUser} onDeleteHandler={confirmRelatedUserDelete} onEditHandler={onRelatedUserEdit} />
	}

	const renderRelatedUsers = (relatedUsers) => {
		return !relatedUsers ? 'keine' : <div className='flex flex-column gap-1 w-full'> {relatedUsers.map((relatedUser) => {
			return <RelatedUserCard key={'relatedusercard_' + relatedUser.id} relatedUser={relatedUser} onDeleteHandler={confirmRelatedUserDelete} onEditHandler={onRelatedUserEdit} readonly={readonly} />
		})}</div>
	}

	return (
		<>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title={'Operative Rolle entfernen'} message={`<span>Sind Sie sich sicher, die Fachberater*in <br/><b>${relatedUser && relatedUser.user ? relatedUser.user.lastname + ', ' + relatedUser.user.firstname : ''}</b><br/> mit der Rolle "${relatedUser ? relatedUser.roleType.display : ''}" ${relatedUserServcieConfig.deleteMessage} zu entfernen?</span>`} labelOk='Ja'
				handleOnClick={onRelatedUserDelete} displayConfirmDialog={displayConfirmDialogDelete} setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />
			<ConfirmDialog title='Fachberater*in als "Verantwortliche Rolle" entfernen' message={`<span>Sind Sie sich sicher, die verantwortliche Fachberater*in <br/><b>${relatedUser && relatedUser.user ? relatedUser.user.lastname + ', ' + relatedUser.user.firstname : ''}</b><br/>${relatedUserServcieConfig.deleteMessage} zu entfernen?<br/>Damit ist keine verantwortliche Person mehr gesetzt!</span>`} labelOk='Ja'
				handleOnClick={onRelatedUsersUpdate} displayConfirmDialog={displayConfirmDialogResponsible} setDisplayConfirmDialog={setDisplayConfirmDialogResponisble} />

			<Dialog header='Operative Rollen' visible={displayRelatedUserDialog} onHide={() => setDisplayRelatedUserDialog(false)} id="dialog-user" className='eaa-inputdialog' >
				<div className='flex font-light mb-3 p-0 '>{'Hier können Sie eine operative Rolle ' + (relatedUserServcieConfig.editMessage) + (relatedUser && relatedUser.id ? ' ändern.' : ' erstellen.')}</div>
				<UserProvider><RelatedUsersForm relatedUser={relatedUser} setFormHasErrors={setFormHasErrors} eaaFormHasError={false} setRelatedUser={setRelatedUser} setShowDialog={() => { }} setDisplayCaseUserDialog={setDisplayRelatedUserDialog} listRelatedUsers={relatedUsers} parentId={parentId} /></UserProvider>
				<div className="flex justify-content-end gap-2 mr-5 my-2">
					<Button className='button-cancel' label="Abbrechen" onClick={() => { setDisplayRelatedUserDialog(false) }} />
					<Button onClick={confirmCaseUserUpdate} disabled={formHasErrors} className="flex " label={relatedUser && relatedUser.id ? 'Ändern' : 'Neu hinzufügen'} />
				</div>
			</Dialog>
			<Card className="relatedusersheet card-sheet" header={renderHeader}>
				<EAADivider className={(hideDivider ? 'hidden ' : '') + 'm-0 p-1'} icon={ENUM_ICONS.PLUS} label='Neue Fachberater*in zuweisen' onClickHandler={() => onRelatedUserEdit(null)} />

				{hideDivider ?
					renderRelatedUsers(relatedUsers) :
					<Carousel className='w-full mt-10 ' showIndicators={false} value={relatedUsers} numVisible={3} numScroll={3} itemTemplate={renderRelatedUserCard} responsiveOptions={responsiveOptions} />
				}
			</Card>
		</>
	)

}