export enum ENUM_SERVICES {
	ADDRESSES = 'addresses',
	ASSOCIATEDCONTACTS = 'associated-contacts',
	ASSOCIATEDCONTACTCOMMENTS = 'associated-contact-comments',
	COMPANIES = 'companies',
	COMPANYCOMMENTS = 'company-comments',
	COMPANYUSERS = 'company-users',
	CONTACTPERSONS = 'contact-persons',
	DATAEXPORTS = 'dataexports',
	EAACASES = 'eaa-cases',
	CASEUSERS = 'case-users',
	CASEEVENTS = 'case-events',
	CASEPERSONS = 'case-persons',
	CASEREQUESTTYPES = 'case-request-types',
	CASEREQUESTS = 'case-requests',
	JOBPROFILES = 'job-openings',
	JOBPROFILECOMMENTS = 'job-opening-comments',
	ORGANIZATIONS = 'organizations',
	PROVIDERACTIVITIES = 'provideractivities',
	PROVIDERACTIVITYUSERS = 'provideractivity-users',
	PROVIDERACTIVITYCOMMENTS = 'provideractivity-comments',
	REQUESTDISTRIBUTIONS = 'request-distributions',
	USERS = 'users',
	USERSETTINGS = 'user-settings',
	REMINDERS = 'reminders',
	UTILS = 'utils',
	TASKAREAS = 'taskareas',
	TASKAREATAGS = 'taskarea-tags',
	REGIONSTAGS = 'region-tags',
	REGIONS = 'regions',
	COMPANYCONTACTPERSONS = 'company-contact-persons',
};