import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useState } from 'react';
import { AssociatedContactCard } from "./AssociatedContactCard";
import { ENUM_ICONS } from "../Components/Icons";
import { ToastError } from "../Utils/ToastError";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { addAssociatedContact, deleteAssociatedContact } from "./AssociatedContactsManager";
import { useAssociatedContactsSelectionList } from '../Persistence/AssociatedContactsContext';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { getData } from "../feathers";

export const ASSOCIATEDCONTACTCONFIG = {
	CASE: { servicename: ENUM_SERVICES.EAACASES, idFieldname: 'eaaCaseId', displayname: 'Falls', deleteMessage: 'vom Fall', editMessage: 'im Fall' },
	COMPANY: { servicename: ENUM_SERVICES.COMPANIES, idFieldname: 'companyId', displayname: 'Unternehmens', deleteMessage: 'vom Unternehmen', editMessage: 'des Unternehmens' },
	PROVIDERACTIVITY: { servicename: ENUM_SERVICES.PROVIDERACTIVITIES, idFieldname: 'provideractivityId', displayname: 'Trägeraktivität', deleteMessage: 'aus der Trägeraktivität', editMessage: 'der Trägeraktivität' },
}

const SERVICENAME = ENUM_SERVICES.ASSOCIATEDCONTACTS;
const CLASSNAME = 'AssociatedContactSheet'
export const AssociatedContactSheet = ({ associatedContacts, onAssociatedContactPatched, readonly, associatedContactServiceConfig, parent }) => {

	const configurationsList = useConfigurationsList();
	const associatedContactsSelectionList = useAssociatedContactsSelectionList();
	const [associatedContact, setAssociatedContact] = useState();
	const [associatedContactId, setAssociatedContactId] = useState();
	const [displayAssociatedContactDialog, setDisplayAssociatedContactDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [toastParams, setToastParams] = useState(null);

	const onAssociatedContactDelete = async () => {
		await deleteAssociatedContact(associatedContact, associatedContactServiceConfig.servicename, parent).then(async () => {
			await onAssociatedContactPatched();
		}).catch((error) => {
			setToastParams({ title: 'Netzwerkkontakt konnte nicht gelöscht werden', message: error.message })
		});
	}

	const confirmAssociatedContactDelete = async (associatedContact) => {
		setAssociatedContact(associatedContact);
		setDisplayConfirmDialogDelete(true)
	}
	const confirmAssociatedContactAdd = async () => {
		await getData(SERVICENAME, associatedContactId).then(async (associatedContact) => {
			await addAssociatedContact(associatedContact, associatedContactServiceConfig.servicename, parent).then(async () => {
				setDisplayAssociatedContactDialog(false);
				await onAssociatedContactPatched();
			})
		})
	}

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Netzwerkkontakte</div>
				<div className='flex '>
					<div className={readonly ? 'hidden' : 'flex w-1rem open-icon'} onClick={() => { setAssociatedContactId(null); setDisplayAssociatedContactDialog(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderAssociatedContacts = () => {
		return !associatedContacts ? 'keine' : <div className='flex flex-column gap-1 w-full'> {associatedContacts.map((associatedContact) => {
			return <AssociatedContactCard key={'associatedcontactcard_' + associatedContact.id} associatedContact={associatedContact} onDeleteHandler={confirmAssociatedContactDelete} readonly={readonly} />
		})}</div>
	}

	return (
		<>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title={'Netzwerkkontakt entfernen'} message={`<span>Sind Sie sich sicher, den Netzwerkkontakt <br/><b>${associatedContact ? associatedContact.name : ''}</b><br/>zu entfernen?</span>`} labelOk='Ja'
				handleOnClick={onAssociatedContactDelete} displayConfirmDialog={displayConfirmDialogDelete} setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />
			<Dialog header='Netzwerkkontakt' visible={displayAssociatedContactDialog} onHide={() => { setAssociatedContactId(null); setDisplayAssociatedContactDialog(false) }} id="dialog-associatedcontact" className='eaa-inputdialog' >
				<div className='flex font-light mb-3 p-0 '>{'Hier können Sie einen Netzwerkkontakt suchen und hinzufügen.'}</div>
				<div className="flex justify-content-end w-full pr-5">
					<Dropdown
						key={'dropdown'}
						panelClassName='p-2 '
						disabled={readonly}
						type='text'
						filter={true}
						filterBy="name,organization"
						itemTemplate={(option) => { return ((option.organization ? option.organization + ' - ' : '') + option.name) }}
						valueTemplate={(option, props) => !option ? <span>{props.placeholder}</span> : ((option.organization ? option.organization + ' - ' : null) + option.name)}
						optionLabel='name'
						optionValue={'id'}
						value={associatedContactId}
						options={associatedContactsSelectionList.filter((e) => { return !associatedContacts.find((f) => f.id === e.id); })}
						onChange={(e) => { setAssociatedContactId(e.value) }}
						placeholder='Netzwerkkontakt wählen'
						className='w-full'
						showClear={true} emptyMessage='Keine Auswahl vorhanden' />
				</div>

				<div className="flex justify-content-end gap-2 mr-5 my-4">
					<Button className='button-cancel' label="Abbrechen" onClick={() => { setDisplayAssociatedContactDialog(false) }} />
					<Button onClick={confirmAssociatedContactAdd} disabled={!associatedContactId} className="flex " label={'Neu hinzufügen'} />
				</div>
			</Dialog>
			{configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1' ? '' : <Card className={"associatedcontactsheet card-sheet"} header={renderHeader}>
				{renderAssociatedContacts()}
			</Card>}
		</>
	)

}