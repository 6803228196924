import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils"
import { useEffect } from "react";
import { logDefault } from "../../Utils/logger";

const CLASSNAME = 'DropdownChangesChecked';
export const DropdownChangesChecked = ({ id, label, editmode, formik, className, itemLabel, itemValue, options, placeholder, filter, onChange, showClear, tabIndex, idError }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'id:' + id, formik.values[id])
	}, [])

	const handleChange = (e) => {
		if (onChange) {
			onChange(e.value)
		}
		formik.handleBlur(e)
		formik.handleChange(e);
	}

	return (
		<div className="field col mr-3">
			<label htmlFor={id} className='block'>{label}</label>
			<Dropdown key={'dropdown-' + id}
				panelClassName='p-2'
				tabIndex={tabIndex}
				disabled={!editmode}
				type='text'
				filter={filter}
				optionLabel={itemLabel}
				optionValue={itemValue}
				id={id}
				value={formik.values[id]}
				options={options}
				onChange={handleChange}
				placeholder={placeholder}
				className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + className}
				showClear={showClear} emptyMessage='Keine Auswahl vorhanden' />
			{getFormErrorMessage(idError ? idError : id)}
		</div>
	)

}

