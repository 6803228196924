import { classNames } from "primereact/utils";
import { useState, useEffect } from "react";
import { logDefault } from "../../Utils/logger";
import { ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Icons";
import { useRemindersList } from "../../Persistence/RemindersContext";
import { addDays, isBefore } from "date-fns";
import { RemindersSheet } from "../../Reminders/RemindersSheet";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";

const CLASSNAME = 'BasicSheetLayout';
export const BasicSheetLayout = ({ mainContent, sideSheets, overlayMenu, reminderService, serviceId }) => {
	const remindersList = useRemindersList();
	const [selectedTab, setSelectedTab] = useState(0);
	const [sideComponent, setSideComponent] = useState(null);
	const [objectReminders, setObjectReminders] = useState([]);
	const [sideSheetReminder, setSideSheetReminder] = useState()

	useEffect(() => {
		if (reminderService) {
			setSideSheetReminder({ index: sideSheets.length, visible: true, icon: ENUM_ICONS.CLOCK + "fa-lg", component: <RemindersSheet servicename={reminderService} id={serviceId} />, badge: <Badge value={objectReminders.length} severity={objectReminders.find(e => e.overdue) ? 'danger' : ''} /> })
		}
	}, [serviceId, objectReminders])

	useEffect(() => {
		const tempSideSheets = sideSheetReminder ? sideSheets.concat([sideSheetReminder]) : sideSheets
		const sideSheet = tempSideSheets ? tempSideSheets.find(sideSheet => selectedTab === sideSheet.index) : null;
		setSideComponent(sideSheet ? sideSheet.component : null)
		logDefault(CLASSNAME, 'info', 'selectedTab:' + selectedTab, sideSheets)
	}, [selectedTab, mainContent])

	useEffect(() => {
		const list = remindersList.filter(e => {
			return e.serviceName === reminderService && ('' + e.serviceId) === ('' + serviceId)
		})
		list.forEach((entry) => {
			entry.overdue = isBefore(addDays(new Date(entry.millisecondsDate), -3), new Date())
		})
		setObjectReminders(list);
	}, [remindersList])

	const renderTabSideSheet = (sideSheet) => {
		return !sideSheet.visible ? '' : <li key={'tabSideSheet-' + sideSheet.index}>
			<a onClick={() => setSelectedTab(sideSheet.index)} className={classNames('p-ripple cursor-pointer px-3 py-2 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround', { 'border-blue-500 text-blue-500 hover:border-blue-500': selectedTab === sideSheet.index, 'text-700 border-transparent': selectedTab !== sideSheet.index })} >
				{sideSheet.awesomeIcon ? <div className="flex flex-wrap justify-content-center p-overlay-badge  awesomeIcon">
					<Icon
						ENUM_AWESOMEICON={sideSheet.awesomeIcon}
						size={sideSheet.awnsomeSize || ENUM_AWESOMEICON_SIZE.X1}
						className={!sideSheet.badge ? '' : ' z-1 relative '}
					/>
					{sideSheet.badge}
				</div> :
					<i className={sideSheet.icon + " mr-2 p-overlay-badge sidesheet"}>{sideSheet.badge}</i>}
			</a >
		</li >
	}

	return <div className="flex m-0 w-full h-full ">
		<div className="flex m-0 w-full h-full overflow-y-auto">
			<div className='mx-auto eaa-maincontent '>{mainContent}</div>
		</div>
		{!sideSheets ? '' :
			<div className="flex bg-white eaa-sidecontent h-full ">
				<div className="flex flex-column eaa-sidecontent overflow-y-auto" >
					<div className='grid m-0 p-0'>
						<div className="col">
							<ul className="card-container pl-3 m-0 list-none flex overflow-x-auto select-none justify-content-start">
								{sideSheets.map(sideSheet => renderTabSideSheet(sideSheet))}
								{!sideSheetReminder ? '' : renderTabSideSheet(sideSheetReminder)}
							</ul>
						</div>
						<div className="col-fixed w-2rem flex  align-content-center">
							<div className='flex align-items-center w-full'>
								{overlayMenu}
							</div>
						</div>
					</div>
					<div className="col-fixed eaa-sidecontent">
						{sideComponent}
					</div>
				</div>
			</div>}
	</div>

}