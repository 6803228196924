import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getData, patchData } from "../feathers";


export async function getAssociatedContact(associatedContactId: any) {
	return await getData(ENUM_SERVICES.ASSOCIATEDCONTACTS, associatedContactId).then((associatedContact) => {
		return associatedContact
	})
}

export async function addAssociatedContact(associatedContact: any, servicename: ENUM_SERVICES, parent: any) {
	const associatedContacts = parent.associatedContacts || [];
	associatedContacts.push(associatedContact)
	return await patchData(servicename, { ...parent, associatedContacts })
}

export async function addAssociatedContactId(associatedContactServiceConfig: any, associatedContactId: any, parent: any) {
	return await getAssociatedContact(associatedContactId).then(async (associatedContact) => {
		await addAssociatedContact(associatedContact, associatedContactServiceConfig.servicename, parent);
		return associatedContact;
	})
}

export async function deleteAssociatedContact(associatedContact: any, servicename: ENUM_SERVICES, parent: any) {
	const associatedContacts: any[] = parent.associatedContacts || [];
	await patchData(servicename, { ...parent, associatedContacts: associatedContacts.filter((e) => e.id !== associatedContact.id) })
}