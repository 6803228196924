import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { CompanyForm, emptyCompany, validateCompany } from "../Companies/CompanyForm";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { TemplateDataView } from "../Components/TemplateDataView";
import { getData, patchData } from "../feathers";
import { ENUM_ROUTES, ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsAdd, useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useConfigurationsList, useDataviewsConfig, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsReader, useUserSwitchMode } from "../Persistence/CurrentUserContext";
import { useDataViewFilterUpdate, useDataViewPaginationUpdate } from '../Persistence/DataViewFilterContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import { format, addDays, isBefore } from "date-fns";
import { useRemindersList } from "../Persistence/RemindersContext";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { EAACaseCreateWizard } from "../EAACase/EAACaseCreateWizard";
import { openCompany, patchCompany } from "../Companies/CompanyManager";
import { copyObject } from "../snippets";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { DEFAULT_EAACASES_FILTER, ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";

const CLASSNAME = 'Dashboard';

export const Dashboard = () => {
	const formatDate = useFormatDate();
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.REMINDERS);
	const currentUser = useCurrentUser();
	const addBreadcrumb = useBreadcrumbsAdd();
	const isReader = useCurrentUserIsReader();
	const remindersList = useRemindersList();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const dataViewFilterUpdate = useDataViewFilterUpdate();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const [eaaCases, setEaaCases] = useState([])
	const [reminder, setReminder] = useState([])
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayConfirmDialogReminderDone, setDisplayConfirmDialogReminderDone] = useState(false);
	const [mounted, setMounted] = useState(false)
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [company, setCompany] = useState();
	const [formHasErrors, setFormHasErrors] = useState();

	useEffect(() => {
		if (configurationsList.CaseStatusList) {
			getData('users', currentUser.id).then((user) => {
				const statusList = configurationsList.CaseStatusList.map((status) => {
					return status.alias === 'INACTIVE' || status.alias === 'CLOSED' ? null : { status, count: 0 }
				}).filter(entry => entry !== null)

				if (user.caseUsers) {
					user.caseUsers.forEach((caseUser) => {
						if (caseUser && caseUser.roleType && caseUser.roleType.alias === 'R') {
							const status = statusList.find(entry => entry.status.alias === caseUser.eaacase.status)
							if (status) {
								status.count = ++status.count;
							}
						}
					})
				}
				setEaaCases(statusList)
			})
			setMounted(true)
		}

	}, [configurationsList])

	const onClickEAACase = async (eaaStatus) => {
		const filters = {
			...DEFAULT_EAACASES_FILTER,
			'company.name': { ...DEFAULT_EAACASES_FILTER['company.name'], value: null },
			displayId: { ...DEFAULT_EAACASES_FILTER.displayId, value: null },
			name: { ...DEFAULT_EAACASES_FILTER.name, value: null },
			millisecondsCreatedAt: { ...DEFAULT_EAACASES_FILTER.millisecondsCreatedAt, value: null },
			'responsible.id': { ...DEFAULT_EAACASES_FILTER['responsible.id'], value: [currentUser.id] },
			status: { ...DEFAULT_EAACASES_FILTER.status, value: [eaaStatus.alias] },
		}
		await dataViewFilterUpdate(DATAVIEW_CONFIG, filters);

		const paginationRows = DATAVIEW_CONFIG.pagination.paginationRows
		await setDataViewPagination(DATAVIEW_CONFIG, { paginationFirst: 0, paginationRows, currentPage: 0 })
		await dataViewFilterUpdate(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.EAACASES), filters);
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fälle', route: ENUM_ROUTES.EAACASES }] })
	}

	const renderEaaCases = () => {
		return eaaCases.map((eaaCase) => {
			return <div key={'eaacase_' + eaaCase.status.alias} onClick={() => { onClickEAACase(eaaCase.status) }} className="flex grid  align-content-start flex-grow-1 h-7rem justify-content-center surface-0 shadow-2 m-2 p-2 border-radius-sm  cursor-pointer">
				<div className="col">
					<div className="flex flex-column">
						<div className="text-lg">{eaaCase.status.display}</div>
						<div className={"mt-2 font-bold text-xl " + ('color-text-' + eaaCase.status.color)} >{`${eaaCase.count} ${eaaCase.count === 1 ? 'Fall' : 'Fälle'}`}</div>
						<div className="flex text-base w-full justify-content-end">Ansehen</div>
					</div>
				</div>
			</div>
		})
	}

	const handleEditReminder = async (id) => {

		await getData('reminders', id).then(async (reminder) => {
			if (reminder.reminded === -1) {
				reminder.reminded = 0
				await patchData(ENUM_SERVICES.REMINDERS, reminder)
			}
			setReminder(reminder);
			setDisplayReminderDialog(true);
		})
	}

	const handleSetReminderDone = async (reminder) => {
		setReminder(reminder);
		setDisplayConfirmDialogReminderDone(true);
	}


	const handleOpenRemindedObject = async (reminder) => {
		if (reminder.reminded === -1) {
			reminder.reminded = 0
			await patchData(ENUM_SERVICES.REMINDERS, reminder)
		}
		const route = reminder.serviceName === 'companies' ? ROUTES['COMPANIES'] : (reminder.serviceName === 'eaa-cases' ? ROUTES['EAACASES'] : (reminder.serviceName === 'provideractivities' ? ROUTES['PROVIDERACTIVITIES'] : (reminder.serviceName === ENUM_SERVICES.ASSOCIATEDCONTACTS ? ROUTES['ASSOCIATEDCONTACTS'] : ROUTES['JOBPROFILES'])))
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + reminder.serviceId }] })
	}
	const onReminderDone = async () => {
		reminder.reminded = '1'
		await patchData('reminders', reminder)
	}
	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const renderContextMenu = (rowData) => {
		return <div className={'open-icon flex w-full h-1rem ' + (rowData.hideEdit ? 'justify-content-end' : 'justify-content-between ')}>
			{rowData.hideEdit ? '' : <i className={ENUM_ICONS.EYE} onClick={() => handleOpenRemindedObject(rowData)} />}
			{userSwitchMode || rowData.hideEdit ? '' : <i className={ENUM_ICONS.PENCIL + 'edit-icon'} onClick={() => handleEditReminder(rowData.id)} />}
			{userSwitchMode ? '' : <i className={ENUM_ICONS.CHECK + 'check-icon'} onClick={() => handleSetReminderDone(rowData)} />}
		</div>
	}

	const renderReminderDate = (rowData) => {
		const date = addDays(new Date(rowData.millisecondsDate), -3)
		const icon = isBefore(date, new Date()) ? <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.BELL} size={ENUM_AWESOMEICON_SIZE.X1} className='text-500' /> : null
		return <div className='flex w-full'>
			<div className='flex flex-grow-1 pt-1'>{formatDate(rowData.millisecondsDate)}</div>
			<div className='flex col-2'>{icon}</div>
		</div>
	}

	const columnsReminders = [
		{ field: "serviceName", sortable: true, filter: "serviceName", header: "Art", style: { width: '110px' }, body: 'renderListIcon' },
		{ field: "referenceName", sortable: true, filter: "referenceName", header: "Name", style: { flexGrow: 1, flexBasis: '200px' } },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Datum", style: { width: '180px' }, body: renderReminderDate },
		{ field: "description", sortable: true, filter: "description", header: "Notiz", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ style: { width: '100px' }, body: renderContextMenu },
	]

	return (
		<div className='mx-auto eaa-dataView select-none'>
			{!reminder ? '' :
				<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header={reminder.serviceName === 'companies' ? 'Unternehmen' : 'Fall'} name={reminder.name} serviceId={reminder.serviceId} serviceName={reminder.servcieName} userId={reminder.userId} reminder={reminder} />
			}
			<ConfirmDialog title='Wiedervorlage abschliessen' message={`<span>Wiedervorlage <br/><b>${reminder.name}</b><br/> als bearbeitet abschliessen?</span>`} labelOk='Ja'
				handleOnClick={onReminderDone} displayConfirmDialog={displayConfirmDialogReminderDone} setDisplayConfirmDialog={setDisplayConfirmDialogReminderDone} />

			<EditDialog
				displayDialog={displayCompanyDialog}
				formik={formikCompany}
				labelButtonOk='Anlegen'
				header='Unternehmen anlegen'
				message='Lege ein neues Unternehmen an'
				patchForm={async () => {
					await patchCompany(formikCompany.values, currentUser).then((company) => {
						updateBreadcrumbs({ home: { icon: ENUM_ICONS.COMPANY }, items: [{ label: ROUTES.COMPANIES.label, route: `${ENUM_ROUTES.COMPANIES}/` + company.id }] })
					})
				}}
				setDisplayDialog={setDisplayCompanyDialog}
				form={<CompanyForm
					formik={formikCompany}
					readOnly={false}
					handleSelectExistingCompany={async (id) => { formikCompany.setTouched({}); openCompany(id, addBreadcrumb) }}
				/>}
			/>

			<div className="flex justify-content-between flex-wrap w-full pt-2">
				<div className="flex  font-semibold text-xl">
					{`Willkommen zurück, ${currentUser.firstname} ${currentUser.lastname}`}
				</div>
				<div className="flex justify-content-end gap-3">
					<EAACaseCreateWizard />
					<Button disabled={isReader} onClick={() => { formikCompany.setValues(copyObject(emptyCompany)); setDisplayCompanyDialog(true) }} className='flex  text-white' label='Unternehmen anlegen' />
				</div>
			</div>

			<div className="card shadow-2 p-4 mt-2 mb-4 bg-white border-round">
				<div className='font-bold mb-4'>Ihre Fallübersicht</div>
				<div className="flex justify-content-center flex-wrap gap-4">
					{renderEaaCases()}
				</div>
			</div>

			{<div className="card shadow-2 p-4 my-4 bg-white border-round" style={{ height: 'calc(100vh - 400px)' }}>
				<div className='font-bold mb-2'>Ihre Wiedervorlagen</div>
				<TemplateDataView
					key='datatable-reminders'
					scrollHeight={'calc(100vh - 580px)'}
					classNameDataTable='flex '
					dataTableList={remindersList}
					dataTableWidth='1430px'
					columns={columnsReminders}
					onDoubleClick={handleEditReminder}
					DATAVIEW_CONFIG={DATAVIEW_CONFIG} />
			</div>}
		</div>
	)

}