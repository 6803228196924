import React, { useContext, useState, useEffect } from "react";
import { updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { fetchDataWithPagination, getSortedList } from "../snippets";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'AssociatedContactsProvider';
const servicename = ENUM_SERVICES.ASSOCIATEDCONTACTS;
const AssociatedContactsContext = React.createContext();
const AssociatedContactsSelectionContext = React.createContext();

export function useAssociatedContactsSelectionList() {
	return useContext(AssociatedContactsSelectionContext);
}

export function useAssociatedContactsList() {
	return useContext(AssociatedContactsContext);
}

export function AssociatedContactsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', '########### ' + providername + ' update');
			updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
			setListSelection(getSortedList(list, 'name', false).filter((entry) => entry.active))
		}

	}, [list, mounted])

	useEffect(() => {
		if (mounted) {
			new Promise(() => { fetchDataWithPagination(ENUM_SERVICES.ASSOCIATEDCONTACTS, setList) })
			return () => { LoggerContext.info(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (

		<AssociatedContactsContext.Provider value={list}>
			<AssociatedContactsSelectionContext.Provider value={listSelection}>
				{children}
			</AssociatedContactsSelectionContext.Provider>
		</AssociatedContactsContext.Provider>
	)
}