import { BreadcrumbsProvider } from './Persistence/Breadcrumbs';
import { ErrorBoundary } from 'react-error-boundary'
import { OrganizationsProvider } from './Persistence/OrganizationsContext';
import { DataViewFilterProvider } from './Persistence/DataViewFilterContext';
import { CompaniesProvider } from './Persistence/CompaniesContext';
import { StrgPressedProvider } from './Persistence/StrgPressed';
import { EAACasesProvider } from './Persistence/EAACasesContext';
import { ProviderActivitiesProvider } from './Persistence/ProviderActivitiesContext';
import { UserProvider } from './Persistence/UserContext';
import { JobOpeningsProvider } from './Persistence/JobOpeningsContext';
import { PostcodesProvider } from './Persistence/PostcodesContext';
import { RegionTagsProvider } from './Persistence/RegionTagsContext';
import { TaskareasProvider } from './Persistence/TaskareasContext';
import { TaskareaTagsProvider } from './Persistence/TaskareaTagsContext';
import { ErrorProvider } from './Persistence/ErrorContext';
import { RemindersProvider } from './Persistence/RemindersContext';
import { RequestDistributionsProvider } from './Persistence/RequestDistributionsContext';
import { CaseRequestTypesProvider } from './Persistence/CaseRequestTypesContext';
import { AssociatedContactsProvider } from './Persistence/AssociatedContactsContext';
import { CaseEventsProvider } from './Persistence/CaseEventsContext';
import { NavigationPage } from './Navigation/NavigationPage';
import { RegionsProvider } from './Persistence/RegionsContext';
import { useConfigurationsComplete } from './Persistence/ConfigurationsContext';

export const ApplicationInit = () => {
	const configurationCompleted = useConfigurationsComplete();
	return (!configurationCompleted ?
		<div className='flex h-full w-full '>
			<div className='flex flex-column w-full h-full justify-content-center '>
				<div className='flex justify-content-center text-6xl'>Das EAA DOQ wird für Sie gestartet</div>
				<div className='flex w-full justify-content-center  '>
					<img src="../icons/logo_eaa-tool.png" className='flex w-6' />
				</div>
			</div>
		</div> :
		<CaseEventsProvider >
			<AssociatedContactsProvider >
				<RequestDistributionsProvider>
					<CompaniesProvider>
						<ProviderActivitiesProvider>
							<JobOpeningsProvider>
								<EAACasesProvider>
									<UserProvider>
										<OrganizationsProvider>
											<PostcodesProvider>
												<RegionTagsProvider>
													<CaseRequestTypesProvider>
														<TaskareaTagsProvider>
															<DataViewFilterProvider>
																<RemindersProvider>
																	<BreadcrumbsProvider>
																		<StrgPressedProvider>
																			<ErrorProvider>
																				<NavigationPage />
																			</ErrorProvider>
																		</StrgPressedProvider>
																	</BreadcrumbsProvider>
																</RemindersProvider>
															</DataViewFilterProvider>
														</TaskareaTagsProvider>
													</CaseRequestTypesProvider>
												</RegionTagsProvider>
											</PostcodesProvider>
										</OrganizationsProvider>
									</UserProvider>
								</EAACasesProvider>
							</JobOpeningsProvider>
						</ProviderActivitiesProvider>
					</CompaniesProvider>
				</RequestDistributionsProvider>
			</AssociatedContactsProvider>
		</CaseEventsProvider>
	)
}