import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES"
import logger, { logDefault } from "../Utils/logger"
import { getData, patchData } from "../feathers"

const CLASSNAME = 'ContactPersonManager'

export const handleSaveCompanyContactPerson = async (changedContactPersonId: number, companyId: number) => {
	/* Wenn company bereits gespeichert wurde -> NUR Assoziation speichern*/
	if (companyId && changedContactPersonId) {
		await getData(ENUM_SERVICES.CONTACTPERSONS, changedContactPersonId).then(async (contactPerson) => {
			const companies = contactPerson.companies ? contactPerson.companies : []
			await getData(ENUM_SERVICES.COMPANIES, companyId).then(async (company) => {
				companies.push(company)
				contactPerson.companies = companies
				await patchData(ENUM_SERVICES.CONTACTPERSONS, contactPerson);
			})
		}).catch((error) => {
			logger.error('Error while handleSave in CompanyContactPersons: ' + error.message)
		})
	}
}

export const patchContactPerson = async (contactPerson: any, currentUser: any, companyContactPersonId: number, companyId: number, handleSave: Function, handleCancel: Function) => {
	const { versionCompanyContactPerson } = contactPerson
	logDefault(CLASSNAME, 'info', 'patchContactPerson', contactPerson)
	await patchData(ENUM_SERVICES.CONTACTPERSONS, contactPerson, currentUser).then(async (contactPerson) => {
		/** 
		 * Wenn die Kontaktperson erfolgreich angelegt ist -> evtl Assoziationen zu Companies oder ContactPersons aktualisieren 
		*/
		const companyContactPerson = { id: companyContactPersonId, version: versionCompanyContactPerson, companyId, contactPersonId: contactPerson.id, contactType: contactPerson.contactType.alias };
		logDefault(CLASSNAME, 'info', 'patchContactPerson companyContactPerson', companyContactPerson)
		await patchData(ENUM_SERVICES.COMPANYCONTACTPERSONS, companyContactPerson)
		if (handleSave) {
			/* Wenn ContactPersonForm eingebettet ist und ein handleSave übergeben wurde, dann über die handleSave das Parent informieren*/
			handleSave(contactPerson.id, companyId)
		}
		handleCancel()
	}).catch((error) => {
		logger.error('Fehler beim Speichern einer Kontaktperson: ' + error.message)
	});
}