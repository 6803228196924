import React, { useContext, useState, useEffect } from "react";
import { findData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { fetchDataWithPagination, getSortedList } from "../snippets";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'RequestDistributionsProvider';
const servicename = 'request-distributions';
const RequestDistributionsContext = React.createContext();
const RequestDistributionsAssignedContext = React.createContext();

export function useRequestDistributionsList() {
	return useContext(RequestDistributionsContext);
}
export function useRequestDistributionsAssignedList() {
	return useContext(RequestDistributionsAssignedContext);
}
export function RequestDistributionsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listAssigned, setListAssigned] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			if (listItemChanged.status !== 0) {
				listItemChanged.removed = true
			}
			logContext(providername, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, (list) => setList(getSortedList(list, 'id', true)), servicename, false)
			updateListEntryInProvider(providername, listItemChanged, listAssigned, setListAssigned, servicename, false)
			setListItemChanged(null);

		}
	}, [listItemChanged, list])

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', '########### ' + providername + ' update list:', list.length);

			updateServiceListener(servicename, listenernames, (_listentry) => { setListItemChanged(_listentry) });
		}
	}, [list, mounted])

	useEffect(() => {
		if (mounted) {
			const query = {
				active: 1,
				status: 0,
				$sort: { id: 0 }
			}
			new Promise(() => { fetchDataWithPagination(ENUM_SERVICES.REQUESTDISTRIBUTIONS, setList, query) })
			const queryAssigned = {
				active: 1,
				status: 5,
				$sort: { id: 0 }
			}
			new Promise(() => { fetchDataWithPagination(ENUM_SERVICES.REQUESTDISTRIBUTIONS, setListAssigned, queryAssigned) })
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<RequestDistributionsAssignedContext.Provider value={listAssigned}>
			<RequestDistributionsContext.Provider value={list}>
				{children}
			</RequestDistributionsContext.Provider>
		</RequestDistributionsAssignedContext.Provider>
	)
}