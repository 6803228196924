import { LoggerContext, logDefault } from "../Utils/logger"
import client from "../feathers"

const CLASSNAME = 'OverviewManager'
export const readOverviewData = async (key: string, limit: number, setList: Function, setCountRecords: Function, query?: string) => {
	const startTime = Date.now()
	const startTime1 = Date.now()
	const total = await client.service('utils').create({ method: 'overviews', key, total: true, query, limit, offset: 0 })
	logDefault(CLASSNAME, 'debug', `readOverviewData ${key} took ${Date.now() - startTime1}ms  total: ${total}`, null)
	let countRecords = 0
	let list: any[] = []
	while (countRecords < total) {
		const startTime2 = Date.now()
		await client.service('utils').create({ method: 'overviews', key, query, limit, offset: countRecords }).then((result: any) => {
			countRecords += limit;
			list = list.concat(result)
		}).catch((error: Error) => { LoggerContext.error("Error overviews ", error) });
		if (setCountRecords) {
			const count = countRecords > total ? 100 : Math.round((countRecords / (total)) * 100)
			setCountRecords(count)
		}
		logDefault(CLASSNAME, 'debug', `----- readOverviewData  ${key} took ${Date.now() - startTime2}ms countRecords:${countRecords} -  count:${list.length} of ${total}`, null)
	}
	if (setList) {
		setList(list)
	}
	logDefault(CLASSNAME, 'info', `readOverviewData ${key} took ${Date.now() - startTime}ms  total: ${total} list:${list.length}`, null)
	return list
}