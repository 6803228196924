import { useState } from 'react';
import { useBreadcrumbsUpdate } from '../Persistence/Breadcrumbs';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useDataviewsConfig, useFormatDate } from '../Persistence/ConfigurationsContext';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { ENUM_ROUTES, ROUTES } from '../Navigation/Routes';

const CLASSNAME = 'OverviewEaaCasesReviewDataview'
export const OverviewEaaCasesReviewDataview = () => {
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const formatDate = useFormatDate();
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW));

	const renderDate = (rowData) => { return (rowData.millisecondsDate ? formatDate(rowData.millisecondsDate) : '---') }
	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	const renderFinders = (rowData) => {
		const entries = []
		if (!rowData.COMPANYVISIT) { entries.push(<div>kein Betriebsbesuch</div>) }
		if (!rowData.CONTACTRESULT) { entries.push(<div>kein Ergebnis</div>) }
		if (rowData.SUSTAINABILITY != null && !rowData.SUSTAINABILITY) { entries.push(<div>kein Nachhaltigkeit</div>) }
		if (rowData.S9_INPROGRESS) { entries.push(<div>offene Anträge</div>) }
		return <div className='flex flex-column'>{entries}</div>
	}
	const columns = [
		{ field: "eaaCaseDisplayId", sortable: true, filter: true, header: "ID", style: { width: '100px' }, alignFrozen: "left", frozen: true },
		{ field: "date", sortable: true, filter: true, header: "Datum des Falls", style: { width: '180px' }, body: renderDate, alignFrozen: "left", frozen: true },
		{ field: "eaaCaseName", sortable: true, filter: "name", header: "Name des Falls", style: { flexGrow: 1, flexBasis: '150px' }, alignFrozen: "left", frozen: true },
		{ field: "companyName", sortable: true, filter: true, header: "Name des Unternehmens", style: { flexGrow: 1, flexBasis: '150px' }, className: '' },
		{ field: "responsible.id", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { width: '200px' }, body: renderResponsible },
		{ field: "personId", sortable: true, filter: true, header: "Fallperson", style: { width: '150px' }, className: '' },
		{ field: "eaaCaseStatus", sortable: true, filter: true, header: "Status des Falls", style: { width: '150px' }, body: 'renderTag' },
		{ field: "countCaseRequests", sortable: true, filter: true, header: "Anträge gesamt", style: { width: '110px' }, className: '' },
		{ field: "COMPANYVISIT", sortable: true, filter: true, header: "Betriebs-besuche", style: { width: '120px' }, className: '', body: (rowData) => rowData.COMPANYVISIT ? 'ja' : 'nein' },
		//{ field: "countCaseRequestsApproved", sortable: true, filter: true, header: "Anträge bewilligt", style: { width: '110px' }, className: '', body: (rowData) => rowData.countCaseRequestsApproved ? 'ja' : 'nein' },
		//{ field: "countCaseRequestsInprogress", sortable: true, filter: true, header: "Anträge offen", style: { width: '110px' }, className: '', body: (rowData) => rowData.countCaseRequestsInprogress ? 'ja' : 'nein' },
		{ field: "CONTACTRESULT", sortable: true, filter: true, header: "Ergebnis", style: { width: '110px' }, className: '', body: (rowData) => rowData.CONTACTRESULT ? 'ja' : 'nein' },
		{ field: "SUSTAINABILITY", sortable: true, filter: true, header: "Nachhaltig-keit", style: { width: '110px' }, className: '', body: (rowData) => rowData.SUSTAINABILITY === null ? '-' : (rowData.SUSTAINABILITY ? 'ja' : 'nein') },
		{ field: "finders", sortable: true, filter: true, header: "Überprüfung", style: { width: '200px' }, className: '', body: renderFinders },

	]

	return <div className='flex flex-column gap-2'>
		<TemplateDataViewPagination
			showOpenLinkColumn={true}
			key='datatable-overviews-associatedcontacts'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			ROUTE={ENUM_ROUTES.EAACASES}
			columns={columns}
			dataTableWidth='100%'
			dataviewConfig={dataviewConfig} />
	</div>
}