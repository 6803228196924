import { parseISO } from "date-fns";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getData, patchData } from "../feathers";

const servicename = ENUM_SERVICES.EAACASES;

export async function getEAACase(eaaCaseId: any, configurationsList: any, isReader: boolean, setReadonly: Function, setEaaCase: Function, setToastParams: Function) {
	return await getData(servicename, eaaCaseId).then((eaaCase) => {
		eaaCase.date = parseISO(eaaCase.date)
		if (configurationsList) {
			eaaCase.caseEvents = eaaCase.caseEvents.map((caseEvent: any) => {
				caseEvent.eventType = caseEvent.eventType ? configurationsList.EventTypeList.find((e: any) => e.alias === caseEvent.eventType) : null;
				return caseEvent;
			});
			if (eaaCase.company && eaaCase.company.contactPersons) {
				eaaCase.company.contactPersons = eaaCase.company.contactPersons.map((contactPerson: any) => {
					contactPerson.contactType = configurationsList.ContactTypeList.find((e: any) => e.alias === contactPerson.contactType);
					return contactPerson;
				})
			}
			eaaCase.caseUsers = eaaCase.caseUsers.map((caseUser: any) => {
				caseUser.roleType = caseUser.roleType ? configurationsList.CaseRoleList.find((e: any) => e.alias === caseUser.roleType) : null;
				return caseUser;
			});
			if (eaaCase.casePerson) {
				eaaCase.casePerson.gender = configurationsList.GenderTypeList.find((e: any) => e.alias === eaaCase.casePerson.gender)
			}
			eaaCase.caseRequests = eaaCase.caseRequests.map((caseRequest: any) => {
				caseRequest.requestDate = parseISO(caseRequest.requestDate);
				caseRequest.completionDate = caseRequest.completionDate ? parseISO(caseRequest.completionDate) : null;
				return caseRequest;
			})
		}
		if (setReadonly) {
			setReadonly(((eaaCase.status.alias || eaaCase.status) === 'INACTIVE') || isReader);
		}
		if (setEaaCase) {
			setEaaCase(eaaCase);
		}
		return eaaCase
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}

export async function patchEAACase(eaaCase: any, configurationsList: any, isReader: boolean, setReadonly: Function, setEaaCase: Function, setToastParams: Function) {
	return await patchData(servicename, eaaCase).then((eaaCase) => {
		return getEAACase(eaaCase.id, configurationsList, isReader, setReadonly, setEaaCase, setToastParams)
	})
}