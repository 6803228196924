import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';
import { useState } from "react";
import { EAADivider } from "../Components/EAADivider";
import { ENUM_ICONS } from '../Components/Icons';
import { ContactPersonCard } from '../ContactPersons/ContactPersonCard';
import { ContactPersonForm } from '../ContactPersons/ContactPersonForm';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { deleteData } from "../feathers";
import { logDefault } from "../Utils/logger";
import { handleSaveCompanyContactPerson } from '../ContactPersons/ContactPersonManager';

const CLASSNAME = 'CompanyContactPersons'
export const CompanyContactPersons = ({ company, contactPersons, numCarousel, onUpdateCompany, hideDivider, readonly }) => {
	const [displayDialog, setDisplayDialog] = useState(false);
	const [companyContactPersonId, setCompanyContactPersonId] = useState();

	const handlerEditContactPerson = (companyContactPersonId) => {
		setCompanyContactPersonId(companyContactPersonId);
		setDisplayDialog(true)
	}

	const handlerDeleteContactPerson = async (companyContactPerson) => {
		logDefault(CLASSNAME, 'info', 'handlerDeleteContactPerson companyContactPerson: ', companyContactPerson)
		await deleteData(ENUM_SERVICES.COMPANYCONTACTPERSONS, companyContactPerson.id).then(async () => {
			/* Die company aktualisieren */
			onUpdateCompany(company.id)
		})
	}

	const handleSaveContactPerson = async (changedContactPersonId) => {
		handleSaveCompanyContactPerson(changedContactPersonId, company.id);
		onUpdateCompany();
		setDisplayDialog(false)
	}

	const renderContactPerson = (contactPerson) => {
		return <ContactPersonCard key={'companyContactPerson_' + contactPerson.id} companyContactPerson={contactPerson} onEditHandler={handlerEditContactPerson} onDeleteHandler={handlerDeleteContactPerson} readonly={readonly} />
	}

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	return (
		<>
			<div className="flex flex-wrap">
				<EAADivider className={(hideDivider || readonly ? 'hidden ' : '') + 'm-0 my-3 p-1'} icon={ENUM_ICONS.PLUS} label='Neue Ansprechpartner*in' onClickHandler={(e) => { setCompanyContactPersonId(null); setDisplayDialog(true) }} />
				<Carousel className='w-full mt-1' showIndicators={false} value={contactPersons} numVisible={numCarousel} numScroll={numCarousel} itemTemplate={renderContactPerson} responsiveOptions={responsiveOptions} />
				<Dialog header={companyContactPersonId ? 'Ansprechparter*in ändern' : 'Neuen Ansprechparter*in hinzufügen'} visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-contact-person" className='eaa-inputdialog' >
					<ContactPersonForm companyId={company ? company.id : null} companyContactPersonId={companyContactPersonId} handleCancel={() => setDisplayDialog(false)} handleSave={handleSaveContactPerson} />
				</Dialog>
			</div>
		</>
	)
}