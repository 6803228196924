import { endOfDay, format, startOfDay, startOfYear } from "date-fns";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useState, useRef, useEffect } from "react";
import client from "../feathers";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsSuperAdmin } from "../Persistence/CurrentUserContext";
import { ToastError } from "../Utils/ToastError";
import { copyObject, getSortedList } from "../snippets";
import { useConfigurationsList, useDataviewsConfig } from "../Persistence/ConfigurationsContext";
import { sortUserList, useUserList } from "../Persistence/UserContext";
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";

export const StatisticCompanyContacts = () => {
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.STATISIC_COMPANYCONTACTS);
	const currentUser = useCurrentUser();
	const userList = useUserList();
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList()
	const [dateFrom, setDateFrom] = useState(startOfYear(new Date()));
	const [dateTo, setDateTo] = useState(new Date());
	const [personId, setPersonId] = useState(0);
	const [personList, setPersonList] = useState([]);
	const [toastParams, setToastParams] = useState(null);
	const [data, setData] = useState(null);
	const dt = useRef(null);

	useEffect(() => {
		const list = copyObject(userList)
		list.push({ lastname: 'Alle', firstname: 'Fachberater', displayname: ' Alle Fachberater', id: 0 })
		const sortedList = sortUserList(list)
		setPersonList(sortedList)
	}, [userList])

	const exportCSV = async () => {
		if (data) {
			const fileName = format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '_Antrags-Statistik_' + format(dateFrom, 'yyyy-MM-dd') + '-bis-' + format(dateTo, 'yyyy-MM-dd') + '.csv';

			const csv = ["Antragsart;Gesamt;" + configurationsList.CaseRequestStatusList.map((status) => status.display)]
			for (let record of data.filter(e => e.id !== 0)) {
				csv.push(record.label + ";" + record.total + ";" + configurationsList.CaseRequestStatusList.map((status) => record[status.alias]).join(';'))
			}
			csv.push(data[0].label + ";" + data[0].total + ";" + configurationsList.CaseRequestStatusList.map((status) => data[0][status.alias]).join(';'))

			var file = new File([csv.join('\n')], fileName, { lastModified: new Date().getTime(), type: "text/csv" });
			let exportUrl = URL.createObjectURL(file);
			var fileLink = document.createElement('a');
			fileLink.href = exportUrl;
			fileLink.download = fileName;
			fileLink.click();
		}
	}

	const onClickCreateStatistic = async (startDate, endDate, personId) => {
		setData(null);
		await client.service('utils').create({ personId: (!isAdmin ? currentUser.id : personId), method: 'statisticCompanyContacts', timezoneOffsetStart: startDate.getTimezoneOffset(), timezoneOffsetEnd: endDate.getTimezoneOffset(), startDate: startOfDay(startDate), endDate: endOfDay(endDate), printStopwatch: isSuperAdmin }).then(async (result) => {
			setData(result)
		}).catch((error) => {
			setToastParams({ title: 'Bei der Erstellung der Statistik ist ein Fehler aufgesteten !', message: error.message })
		});
	}

	const exportFunction = (entry) => {
		const value = {
			'ID': entry.displayId,
			'Unternehmen': entry.name,
			'Kontakte im Zeitraum': entry.countEvents,
			'Anträge im Zeitraum': entry.countRequests,
			'Betriebsbesuche im Zeitraum': entry.countG1,
			'Fälle': entry.countCases,
		}
		return value
	}

	const columns = [
		{ field: "displayId", sortable: true, header: "ID", style: { width: '100px' }, body: null },
		{ field: "name", sortable: true, filter: "name", header: "Unternehmen", style: { flexGrow: 1, flexBasis: '400px' }, body: null },
		{ field: "countEvents", header: "Kontakte im Zeitraum", style: { width: '150px' }, body: null },
		{ field: "countRequests", header: "Anträge im Zeitraum", style: { width: '150px' }, body: null },
		{ field: "countG1", header: "Betriebsbesuche im Zeitraum", style: { width: '150px' }, body: null },
		{ field: "countCases", header: "Anzahl zugehöriger Fälle", style: { width: '200px' }, body: null },
	]

	return <div className="card shadow-2 p-4 my-4 bg-white">
		<div className='font-bold mb-4'>Arbeitgeber-Statistik</div>
		<div className="p-fluid grid formgrid gap-4">
			<div className={isAdmin ? 'field col-fixed w-15rem' : 'hidden'}>
				<label htmlFor="dateCommonTo">Person</label>
				<Dropdown id="personlist"
					showClear
					filter
					filterBy="displayname"
					showFilterClear
					name="personlist"
					optionLabel="displayname"
					optionValue="id"
					value={personId}
					options={personList}
					onChange={(e) => setPersonId(e.value)} autoFocus className={'bg-white w-full'} />
			</div>
			<div className="field col-fixed w-15rem">
				<label htmlFor="datePersFrom">Von</label>
				<Calendar maxDate={dateTo} id="datePersFrom" value={dateFrom} onChange={(e) => { setData(null); setDateFrom(e.value) }} showIcon dateFormat="dd.mm.yy" />
			</div>
			<div className="field col-fixed w-15rem">
				<label htmlFor="datePersTo">Bis</label>
				<Calendar minDate={dateFrom} maxDate={new Date()} id="datePersTo" value={dateTo} onChange={(e) => { setData(null); setDateTo(e.value) }} showIcon dateFormat="dd.mm.yy" />
			</div>
		</div>
		<div className='flex'>
			<Button disabled={dateFrom == null || dateTo == null} onClick={() => onClickCreateStatistic(dateFrom, dateTo, personId)} className='flex  text-white' label='Statistik generieren' />
		</div>
		<div className='overflow-auto w-full mt-4' >
			{<TemplateDataView
				classNameDataTable={'w-full'}
				dataTableWidth={'100%'}
				scrollHeight={'calc(100vh - 550px)'}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
				columns={columns}
				exportColumns={6}
				dataTableList={data}
				exportFunction={exportFunction}
			/>}
		</div>
		<div className='hidden my-2'>
			{!data ? '' : <Button type="button" icon="pi pi-external-link" iconPos="left" label="Als CSV exportieren" onClick={exportCSV}></Button>}
		</div>
		<ToastError toastParams={toastParams} />
	</div>
}
