import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

export const REMINDERSERVICECONFIG = {
	'eaa-cases': { servicename: ENUM_SERVICES.CASEUSERS, displayname: 'Falls', header: 'Fall' },
	'companies': { servicename: ENUM_SERVICES.COMPANYUSERS, displayname: 'Unternehmens', header: 'Unternehmen' },
	'provideractivities': { servicename: ENUM_SERVICES.PROVIDERACTIVITYUSERS, displayname: 'Trägeraktivität', header: 'Trägeraktivität' },
	'job-openings': { servicename: ENUM_SERVICES.JOBPROFILES, displayname: 'Stellenprofils', header: 'Stellenprofil' },
	'associated-contacts': { servicename: ENUM_SERVICES.ASSOCIATEDCONTACTS, displayname: 'Netzwerkkontakts', header: 'Netzwerkkontakt' },
}

export const getEmptyReminder = (serviceName: String, serviceId: Number, userId: Number, name: String) => {
	const reminder = { id: null, description: '', date: new Date(), serviceName, serviceId, userId, name, reminded: 0 }
	return reminder;
}